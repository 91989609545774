import { Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { option_type, union_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { head as head_1, contains, tryLast, singleton, choose, map } from "../fable_modules/fable-library.3.7.12/Array.js";
import { head } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { contains as contains_1, replace, split } from "../../../Fame/Core/String.fs.js";
import { isNullOrEmpty, split as split_1 } from "../fable_modules/fable-library.3.7.12/String.js";
import { stringHash } from "../fable_modules/fable-library.3.7.12/Util.js";
import * as string_similarity from "string-similarity";
import { map as map_1 } from "../fable_modules/fable-library.3.7.12/Option.js";
import { AnswerState_$007CValue$007C_$007C, AnswerValue } from "../../Survey/AnswerQuestion.fs.js";
import { SurveyCommand } from "../../Survey/Api.fs.js";

export class Email_Suggestion extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["FixTypo"];
    }
}

export function Email_Suggestion$reflection() {
    return union_type("Fame.Survey.UI.SurveyPage.Suggestions.Email.Suggestion", [], Email_Suggestion, () => [[["found", string_type], ["suggested", string_type], ["newValue", string_type]]]);
}

const Email_commonHostNames = ["alice-dsl.net", "aol.com", "aol.de", "arcor.de", "berlin.de", "elldus.de", "email.de", "freenet.de", "gmail.com", "gmx.com", "gmx.de", "gmx.net", "googlemail.com", "hotmail.com", "hotmail.de", "icloud.com", "kabelmail.de", "live.com", "live.de", "magenta.de", "mail.de", "mail.ru", "mailbox.org", "me.com", "msn.com", "o2online.de", "online.de", "onlinehome.de", "outlook.com", "outlook.de", "posteo.de", "primacom.net", "rocketmail.com", "seznam.cz", "t-online.de", "theed.support", "theed.technology", "vodafone.de", "vodafonemail.de", "web.de", "wp.pl", "yahoo.com", "yahoo.de", "yandex.ru", "ymail.com"];

const Email_commonHostNamesWithoutTld = map((h) => head(split(".".split(""), h)), Email_commonHostNames);

export function Email_getSuggestions(emailAddress) {
    let matchValue, hostname, hostname_1, hostname_2, toFixTypo, withoutTld, searchHostNames, ratings;
    return choose((x_2) => x_2, singleton((matchValue = tryLast(split_1(emailAddress, singleton("@"))), (matchValue != null) ? (((hostname = matchValue, isNullOrEmpty(hostname) ? true : contains(hostname, Email_commonHostNames, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    }))) ? ((hostname_1 = matchValue, void 0)) : ((matchValue != null) ? ((hostname_2 = matchValue, (toFixTypo = ((newHostName) => (new Email_Suggestion(0, hostname_2, newHostName, replace(hostname_2, newHostName, emailAddress)))), (withoutTld = head(split(".".split(""), hostname_2)), (searchHostNames = (contains(withoutTld, Email_commonHostNamesWithoutTld, {
        Equals: (x_1, y_1) => (x_1 === y_1),
        GetHashCode: stringHash,
    }) ? Email_commonHostNames.filter((h) => (h.indexOf(withoutTld) === 0)) : Email_commonHostNames), (searchHostNames.length === 1) ? toFixTypo(head_1(searchHostNames)) : ((ratings = string_similarity.findBestMatch(hostname_2, searchHostNames), (ratings.bestMatch.rating < 0.5) ? (void 0) : toFixTypo(ratings.bestMatch.target)))))))) : (() => {
        throw (new Error("Match failure"));
    })())) : (void 0))));
}

export class Suggestion extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailSuggestion", "DualEmailSuggestion"];
    }
}

export function Suggestion$reflection() {
    return union_type("Fame.Survey.UI.SurveyPage.Suggestions.Suggestion", [], Suggestion, () => [[["Item", Email_Suggestion$reflection()]], [["Item1", Email_Suggestion$reflection()], ["second", option_type(string_type)]]]);
}

export function SuggestionModule_toSurveyCommand(questionId, suggestion) {
    if (suggestion.tag === 1) {
        const found = suggestion.fields[0].fields[0];
        return new SurveyCommand(0, questionId, new AnswerValue(9, suggestion.fields[0].fields[2], map_1((s) => {
            if (!contains_1(found, s)) {
                return s;
            }
            else {
                return replace(found, suggestion.fields[0].fields[1], s);
            }
        }, suggestion.fields[1])));
    }
    else {
        return new SurveyCommand(0, questionId, new AnswerValue(8, suggestion.fields[0].fields[2]));
    }
}

export function getSuggestions(answeredQuestionType) {
    let pattern_matching_result, value;
    if (answeredQuestionType.tag === 8) {
        const activePatternResult17605 = AnswerState_$007CValue$007C_$007C()(answeredQuestionType.fields[0]);
        if (activePatternResult17605 != null) {
            pattern_matching_result = 0;
            value = activePatternResult17605;
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return map((arg0) => (new Suggestion(0, arg0)), Email_getSuggestions(value));
        }
        case 1: {
            let pattern_matching_result_1, second, value_1;
            if (answeredQuestionType.tag === 9) {
                if (answeredQuestionType.fields[0].tag === 2) {
                    const activePatternResult17604 = AnswerState_$007CValue$007C_$007C()(answeredQuestionType.fields[0].fields[0]);
                    if (activePatternResult17604 != null) {
                        if (activePatternResult17604[0] != null) {
                            pattern_matching_result_1 = 0;
                            second = activePatternResult17604[1];
                            value_1 = activePatternResult17604[0];
                        }
                        else {
                            pattern_matching_result_1 = 1;
                        }
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return map((s) => (new Suggestion(1, s, second)), Email_getSuggestions(value_1));
                }
                case 1: {
                    return new Array(0);
                }
            }
        }
    }
}

