import { toString, Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { list_type, record_type, float64_type, bool_type, class_type, array_type, union_type, string_type, unit_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { FSharpResult$2 } from "../fable_modules/fable-library.3.7.12/Choice.js";
import { AnswerValue, SectionId, OpenSurvey, SurveyId, SectionId$reflection, OpenSurvey$reflection, FinishedSurvey$reflection } from "../../Survey/AnswerQuestion.fs.js";
import { toDomain, SaveType, SaveType$reflection } from "../Service.fs.js";
import { SurveyTypeId, QuestionId$reflection } from "../../Survey/CommonTypes.fs.js";
import { SuggestionModule_toSurveyCommand, getSuggestions, Suggestion$reflection } from "./Suggestions.fs.js";
import { map as map_1, tryFind as tryFind_1, tryFindIndex, tryItem, tryHead, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { add, tryFind, empty as empty_1 } from "../fable_modules/fable-library.3.7.12/Map.js";
import { execute, SurveyOperation, SurveyCommand, SurveyOperation$reflection } from "../../Survey/Api.fs.js";
import { some, bind, map, defaultArg } from "../fable_modules/fable-library.3.7.12/Option.js";
import { equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Cmd_OfAsyncWith_either, Cmd_OfFunc_result, Cmd_OfAsync_start, Cmd_OfAsyncWith_perform } from "../fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { sleep } from "../fable_modules/fable-library.3.7.12/Async.js";
import { Uri } from "../fable_modules/fable-library.3.7.12/Uri.js";
import { Cmd_map, Cmd_ofSub, Cmd_batch, Cmd_none } from "../fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { fromSeconds } from "../fable_modules/fable-library.3.7.12/TimeSpan.js";
import { openSurveyApply } from "../../Survey/Projections.fs.js";
import { toString as toString_1 } from "../../../Fame/Core/Exception.fs.js";

export class SaveResult extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ManualSaveResult", "AutoSaveResult", "FinishSaveResult"];
    }
}

export function SaveResult$reflection() {
    return union_type("Fame.Survey.UI.SurveyPage.State.SaveResult", [], SaveResult, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [FinishedSurvey$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", FinishedSurvey$reflection()]], [["ErrorValue", string_type]]])]]]);
}

export class SaveState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Idle", "Saving", "Saved"];
    }
}

export function SaveState$reflection() {
    return union_type("Fame.Survey.UI.SurveyPage.State.SaveState", [], SaveState, () => [[], [["Item", SaveType$reflection()]], [["Item", SaveResult$reflection()]]]);
}

export class UploadState extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Idle", "Running", "Failed"];
    }
}

export function UploadState$reflection() {
    return union_type("Fame.Survey.UI.SurveyPage.State.UploadState", [], UploadState, () => [[], [], [["Item", string_type]]]);
}

export class SurveyPage extends Record {
    constructor(Survey, AnswerSuggestions, SelectedSectionId, ShouldDisplayRequiredWarnings, UploadStates, SaveState, AutoSaveTimeoutId) {
        super();
        this.Survey = Survey;
        this.AnswerSuggestions = AnswerSuggestions;
        this.SelectedSectionId = SelectedSectionId;
        this.ShouldDisplayRequiredWarnings = ShouldDisplayRequiredWarnings;
        this.UploadStates = UploadStates;
        this.SaveState = SaveState;
        this.AutoSaveTimeoutId = AutoSaveTimeoutId;
    }
}

export function SurveyPage$reflection() {
    return record_type("Fame.Survey.UI.SurveyPage.State.SurveyPage", [], SurveyPage, () => [["Survey", OpenSurvey$reflection()], ["AnswerSuggestions", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [QuestionId$reflection(), array_type(Suggestion$reflection())])], ["SelectedSectionId", SectionId$reflection()], ["ShouldDisplayRequiredWarnings", bool_type], ["UploadStates", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [QuestionId$reflection(), UploadState$reflection()])], ["SaveState", SaveState$reflection()], ["AutoSaveTimeoutId", float64_type]]);
}

export const emptyOpenSurvey = new OpenSurvey(new SurveyId(0, ""), new SurveyTypeId(0, ""), "", new Array(0), void 0, void 0, void 0, void 0, empty());

export const emptySectionId = new SectionId(0, "");

export const emptySurveyPage = new SurveyPage(emptyOpenSurvey, empty_1(), emptySectionId, false, empty_1(), new SaveState(0), -1);

export class SurveyMsg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["UploadFile", "UploadFinished", "SurveyOperations", "SurveyOperation", "SurveyOperationErrored", "ShowPreviousSection", "ShowNextSection", "ShowSection", "ScrollToSelectedSection", "ApplySuggestion", "IgnoreSuggestion", "AutoSaveScheduled", "Save", "SaveFinished"];
    }
}

export function SurveyMsg$reflection() {
    return union_type("Fame.Survey.UI.SurveyPage.State.SurveyMsg", [], SurveyMsg, () => [[["Item1", QuestionId$reflection()], ["Item2", class_type("Browser.Types.File", void 0, File)]], [["Item1", QuestionId$reflection()], ["Item2", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]])]], [["Item", list_type(SurveyOperation$reflection())]], [["Item", SurveyOperation$reflection()]], [["Item", string_type]], [], [], [["Item", SectionId$reflection()]], [], [["Item1", QuestionId$reflection()], ["Item2", Suggestion$reflection()]], [["Item1", QuestionId$reflection()], ["Item2", Suggestion$reflection()]], [["Item", float64_type]], [["Item", SaveType$reflection()]], [["Item", SaveResult$reflection()]]]);
}

export function init(survey) {
    return new SurveyPage(survey, emptySurveyPage.AnswerSuggestions, defaultArg(map((s) => s.Id, tryHead(survey.Sections)), emptySectionId), emptySurveyPage.ShouldDisplayRequiredWarnings, emptySurveyPage.UploadStates, emptySurveyPage.SaveState, emptySurveyPage.AutoSaveTimeoutId);
}

function tryFindSectionIdWithOffset(sections, currentSectionId, offset) {
    return map((section) => section.Id, bind((currentIndex) => tryItem(currentIndex + offset, sections), tryFindIndex((s) => equals(s.Id, currentSectionId), sections)));
}

const scrollToSelectedSectionCmd = Cmd_OfAsyncWith_perform((x) => {
    Cmd_OfAsync_start(x);
}, () => sleep(50), void 0, () => (new SurveyMsg(8)));

function removeSuggestion(questionId, suggestion, answerSuggestions) {
    const matchValue = tryFind(questionId, answerSuggestions);
    if (matchValue != null) {
        const suggestions = matchValue;
        return add(questionId, suggestions.filter((y) => (!equals(suggestion, y))), answerSuggestions);
    }
    else {
        return answerSuggestions;
    }
}

export function updateUploadState(model, questionId, uploadState) {
    return new SurveyPage(model.Survey, model.AnswerSuggestions, model.SelectedSectionId, model.ShouldDisplayRequiredWarnings, add(questionId, uploadState, model.UploadStates), model.SaveState, model.AutoSaveTimeoutId);
}

export function update(services, msg, model) {
    let services_1;
    if (msg.tag === 1) {
        if (msg.fields[1].tag === 0) {
            const cmd_1 = Cmd_OfFunc_result(new SurveyMsg(3, new SurveyOperation(1, new SurveyCommand(0, msg.fields[0], new AnswerValue(6, Uri.create(msg.fields[1].fields[0]))))));
            return [updateUploadState(model, msg.fields[0], new UploadState(0)), cmd_1];
        }
        else {
            return [updateUploadState(model, msg.fields[0], new UploadState(2, msg.fields[1].fields[0])), Cmd_none()];
        }
    }
    else if (msg.tag === 5) {
        return [new SurveyPage(model.Survey, model.AnswerSuggestions, defaultArg(tryFindSectionIdWithOffset(model.Survey.Sections, model.SelectedSectionId, -1), model.SelectedSectionId), model.ShouldDisplayRequiredWarnings, model.UploadStates, model.SaveState, model.AutoSaveTimeoutId), scrollToSelectedSectionCmd];
    }
    else if (msg.tag === 6) {
        return [new SurveyPage(model.Survey, model.AnswerSuggestions, defaultArg(tryFindSectionIdWithOffset(model.Survey.Sections, model.SelectedSectionId, 1), model.SelectedSectionId), model.ShouldDisplayRequiredWarnings, model.UploadStates, model.SaveState, model.AutoSaveTimeoutId), scrollToSelectedSectionCmd];
    }
    else if (msg.tag === 7) {
        const _arg1 = tryFind_1((s) => equals(s.Id, msg.fields[0]), model.Survey.Sections);
        if (_arg1 == null) {
            return [model, Cmd_none()];
        }
        else {
            return [new SurveyPage(model.Survey, model.AnswerSuggestions, msg.fields[0], model.ShouldDisplayRequiredWarnings, model.UploadStates, model.SaveState, model.AutoSaveTimeoutId), scrollToSelectedSectionCmd];
        }
    }
    else if (msg.tag === 8) {
        let el;
        const arg00 = toString(model.SelectedSectionId);
        el = document.getElementById(arg00);
        el.scrollIntoView(true);
        return [model, Cmd_none()];
    }
    else if (msg.tag === 2) {
        return [model, Cmd_batch(map_1((arg_1) => Cmd_OfFunc_result(new SurveyMsg(3, arg_1)), msg.fields[0]))];
    }
    else if (msg.tag === 3) {
        if (msg.fields[0].tag === 0) {
            const autoSaveCmd = Cmd_ofSub((dispatch) => {
                const timeout = (~(~fromSeconds(5))) | 0;
                dispatch(new SurveyMsg(11, window.setTimeout((_arg1_1) => {
                    dispatch(new SurveyMsg(12, new SaveType(0)));
                }, timeout)));
            });
            const nextAnswerSuggestions = (msg.fields[0].fields[0].tag === 2) ? add(msg.fields[0].fields[0].fields[0], getSuggestions(msg.fields[0].fields[0].fields[1]), model.AnswerSuggestions) : model.AnswerSuggestions;
            return [new SurveyPage(openSurveyApply(model.Survey, msg.fields[0].fields[0]), nextAnswerSuggestions, model.SelectedSectionId, model.ShouldDisplayRequiredWarnings, model.UploadStates, model.SaveState, model.AutoSaveTimeoutId), autoSaveCmd];
        }
        else {
            return [model, (services_1 = toDomain(services), Cmd_OfAsyncWith_perform((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, (operation_1) => execute(services_1, operation_1), msg.fields[0], (_arg2) => {
                if (_arg2.tag === 1) {
                    return new SurveyMsg(4, _arg2.fields[0]);
                }
                else {
                    return new SurveyMsg(2, _arg2.fields[0]);
                }
            }))];
        }
    }
    else if (msg.tag === 4) {
        console.error(some(msg.fields[0]));
        return [model, Cmd_none()];
    }
    else if (msg.tag === 9) {
        const cmd_4 = Cmd_OfFunc_result(new SurveyMsg(3, new SurveyOperation(1, SuggestionModule_toSurveyCommand(msg.fields[0], msg.fields[1]))));
        return [new SurveyPage(model.Survey, removeSuggestion(msg.fields[0], msg.fields[1], model.AnswerSuggestions), model.SelectedSectionId, model.ShouldDisplayRequiredWarnings, model.UploadStates, model.SaveState, model.AutoSaveTimeoutId), cmd_4];
    }
    else if (msg.tag === 10) {
        return [new SurveyPage(model.Survey, removeSuggestion(msg.fields[0], msg.fields[1], model.AnswerSuggestions), model.SelectedSectionId, model.ShouldDisplayRequiredWarnings, model.UploadStates, model.SaveState, model.AutoSaveTimeoutId), Cmd_none()];
    }
    else if (msg.tag === 11) {
        window.clearTimeout(model.AutoSaveTimeoutId);
        return [new SurveyPage(model.Survey, model.AnswerSuggestions, model.SelectedSectionId, model.ShouldDisplayRequiredWarnings, model.UploadStates, model.SaveState, msg.fields[0]), Cmd_none()];
    }
    else if (msg.tag === 12) {
        window.clearTimeout(model.AutoSaveTimeoutId);
        const matchValue = model.SaveState;
        let pattern_matching_result;
        if (matchValue.tag === 1) {
            if (equals(matchValue.fields[0], msg.fields[0])) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [model, Cmd_none()];
            }
            case 1: {
                const toCmd = (saveFn, toSaveResult) => Cmd_map((arg_4) => (new SurveyMsg(13, toSaveResult(arg_4))), Cmd_OfAsyncWith_either((x_3) => {
                    Cmd_OfAsync_start(x_3);
                }, saveFn, model.Survey, (x_2) => x_2, (ex_1) => (new FSharpResult$2(1, toString_1(ex_1)))));
                return [new SurveyPage(model.Survey, model.AnswerSuggestions, model.SelectedSectionId, model.ShouldDisplayRequiredWarnings, model.UploadStates, new SaveState(1, msg.fields[0]), model.AutoSaveTimeoutId), (msg.fields[0].tag === 1) ? toCmd(services.SaveSurvey, (arg0_12) => (new SaveResult(0, arg0_12))) : ((msg.fields[0].tag === 2) ? toCmd(services.FinishSurvey, (arg0_13) => (new SaveResult(2, arg0_13))) : toCmd(services.AutoSaveSurvey, (arg0_11) => (new SaveResult(1, arg0_11))))];
            }
        }
    }
    else if (msg.tag === 13) {
        let pattern_matching_result_1, err_1;
        if (msg.fields[0].tag === 0) {
            if (msg.fields[0].fields[0].tag === 1) {
                pattern_matching_result_1 = 0;
                err_1 = msg.fields[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else if (msg.fields[0].tag === 2) {
            if (msg.fields[0].fields[0].tag === 1) {
                pattern_matching_result_1 = 0;
                err_1 = msg.fields[0].fields[0].fields[0];
            }
            else {
                pattern_matching_result_1 = 1;
            }
        }
        else if (msg.fields[0].fields[0].tag === 1) {
            pattern_matching_result_1 = 0;
            err_1 = msg.fields[0].fields[0].fields[0];
        }
        else {
            pattern_matching_result_1 = 1;
        }
        switch (pattern_matching_result_1) {
            case 0: {
                console.error(some(err_1));
                break;
            }
        }
        return [new SurveyPage(model.Survey, model.AnswerSuggestions, model.SelectedSectionId, model.ShouldDisplayRequiredWarnings, model.UploadStates, new SaveState(2, msg.fields[0]), model.AutoSaveTimeoutId), Cmd_none()];
    }
    else {
        const cmd = Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, () => services.UploadFile(msg.fields[1]), void 0, (result) => (new SurveyMsg(1, msg.fields[0], result)), (ex) => (new SurveyMsg(1, msg.fields[0], new FSharpResult$2(1, toString_1(ex)))));
        return [updateUploadState(model, msg.fields[0], new UploadState(1)), cmd];
    }
}

