import { Localization_get, Locale_getLocale } from "../../Fame/Fable/Locales.fs.js";
import { ResourcesModule_get } from "../Survey/Resources.fs.js";
import { ProgramModule_mkProgram, ProgramModule_runWith } from "./fable_modules/Fable.Elmish.3.1.0/program.fs.js";
import { now } from "./fable_modules/fable-library.3.7.12/DateOffset.js";
import { add, tryFind, toSeq, ofSeq } from "./fable_modules/fable-library.3.7.12/Map.js";
import { map, choose } from "./fable_modules/fable-library.3.7.12/Seq.js";
import { ofArray, empty, singleton, append, fold, head, tail, isEmpty, ofSeq as ofSeq_1 } from "./fable_modules/fable-library.3.7.12/List.js";
import { splitAt, skip, split } from "../../Fame/Core/String.fs.js";
import { trimEnd, printf, toText, join } from "./fable_modules/fable-library.3.7.12/String.js";
import { map as map_1 } from "./fable_modules/fable-library.3.7.12/Option.js";
import { SurveyId } from "../Survey/AnswerQuestion.fs.js";
import { uncurry } from "./fable_modules/fable-library.3.7.12/Util.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.12/Choice.js";
import { toString } from "./fable_modules/fable-library.3.7.12/Types.js";
import { Config } from "./Service.fs.js";
import { Program_withReactBatched } from "./fable_modules/Fable.Elmish.React.3.0.1/react.fs.js";
import { update, init } from "./State.fs.js";
import { view } from "./View.fs.js";

export function start() {
    let copyOfStruct, queryParams, paramsToQueryString, matchValue_1, hubUri_1, surveyId_1, token, endpointUri_1;
    const locale = Locale_getLocale();
    const localization = Localization_get(locale);
    const resources = ResourcesModule_get(locale)(localization);
    ProgramModule_runWith([resources, localization, (copyOfStruct = now(), copyOfStruct.offset), (queryParams = ofSeq(choose((s) => {
        const matchValue = ofSeq_1(split(["="], s));
        let pattern_matching_result, key, value;
        if (!isEmpty(matchValue)) {
            if (!isEmpty(tail(matchValue))) {
                if (isEmpty(tail(tail(matchValue)))) {
                    pattern_matching_result = 0;
                    key = head(matchValue);
                    value = head(tail(matchValue));
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
        switch (pattern_matching_result) {
            case 0: {
                return [key, value];
            }
            case 1: {
                return void 0;
            }
        }
    }, split(["\u0026"], skip(1, document.location.search)))), (paramsToQueryString = ((parameters) => {
        const _arg1 = join("\u0026", map((tupledArg) => toText(printf("%s=%s"))(tupledArg[0])(tupledArg[1]), toSeq(parameters)));
        if (_arg1 === "") {
            return "";
        }
        else {
            return toText(printf("?%s"))(_arg1);
        }
    }), (matchValue_1 = [map_1((arg00) => window.decodeURIComponent(arg00), tryFind("endpoint", queryParams)), map_1((arg0) => (new SurveyId(0, arg0)), map_1((guidStr) => {
        if (guidStr.length !== 32) {
            return guidStr;
        }
        else {
            return join("-", fold(uncurry(2, (tupledArg_1) => {
                const parts = tupledArg_1[0];
                const rest = tupledArg_1[1];
                return (pos) => {
                    const _arg2 = splitAt(pos, rest);
                    if (_arg2 != null) {
                        const second = _arg2[1];
                        return [append(parts, singleton(_arg2[0])), second];
                    }
                    else {
                        return [parts, rest];
                    }
                };
            }), [empty(), guidStr], ofArray([8, 4, 4, 4, 12]))[0]);
        }
    }, tryFind("surveyid", queryParams))), tryFind("hub", queryParams)], (matchValue_1[0] == null) ? ((matchValue_1[1] == null) ? ((matchValue_1[2] == null) ? (new FSharpResult$2(1, resources.EndpointAndIdMissing)) : (new FSharpResult$2(1, resources.IdMissing))) : ((matchValue_1[2] == null) ? (new FSharpResult$2(1, resources.HubUriMissing)) : ((hubUri_1 = matchValue_1[2], (surveyId_1 = matchValue_1[1], (token = tryFind("accesstoken", queryParams), new FSharpResult$2(0, new Config((parameters_2) => {
        let withSurvey, _arg3;
        const arg40 = paramsToQueryString((withSurvey = add("surveyid", toString(surveyId_1), parameters_2), (_arg3 = token, (_arg3 != null) ? add("accesstoken", _arg3, withSurvey) : withSurvey)));
        const arg20_2 = (token == null) ? "" : "/token";
        const arg10_3 = trimEnd(hubUri_1, "/");
        return toText(printf("%s%s%s%s"))(arg10_3)(arg20_2)("/api/questionaire/survey")(arg40);
    })))))))) : ((endpointUri_1 = matchValue_1[0], new FSharpResult$2(0, new Config((parameters_1) => {
        const arg20_1 = paramsToQueryString(parameters_1);
        return toText(printf("%s%s"))(endpointUri_1)(arg20_1);
    })))))))], Program_withReactBatched("elmish-app", ProgramModule_mkProgram((tupledArg_2) => init(tupledArg_2[0], tupledArg_2[1], tupledArg_2[2], tupledArg_2[3]), update, view)));
}

start();

(function () {
    const matchValue = navigator.serviceWorker;
    if (matchValue != null) {
        const serviceWorkerContainer = matchValue;
        serviceWorkerContainer.getRegistrations().then((registrations) => {
            registrations.forEach((r) => {
                r.unregister();
            });
        });
    }
})();

