import { toString } from "../fable_modules/fable-library.3.7.12/Date.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { singleton as singleton_1, cons, empty } from "../fable_modules/fable-library.3.7.12/List.js";
import { input as input_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/./Input.fs.js";
import { Option, IInputType } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { empty as empty_1, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { value as value_1, some } from "../fable_modules/fable-library.3.7.12/Option.js";
import { DOMAttr, HTMLAttr, Prop } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { toString as toString_1 } from "../fable_modules/fable-library.3.7.12/Types.js";
import { isNullOrEmpty } from "../fable_modules/fable-library.3.7.12/String.js";
import { DateValue } from "../../Survey/AnswerQuestion.fs.js";
import { addHours, date, fromDate } from "../fable_modules/fable-library.3.7.12/DateOffset.js";
import { uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";

const viewInternal = (() => {
    const formatIsoDate = (dateTimeOffset) => toString(dateTimeOffset, "yyyy-MM-dd");
    return FunctionComponent_Of_Z5A158BBF((tupledArg) => {
        const meta = tupledArg[2];
        const value = tupledArg[3];
        const dispatch = tupledArg[4];
        return div(empty(), singleton_1(input_1(cons(new Option(1, new IInputType(3)), toList(delay(() => append(tupledArg[0], delay(() => singleton(new Option(15, toList(delay(() => append(singleton(new Prop(1, (el) => {
            if (!(el == null)) {
                if (el.type !== "date") {
                    console.error(some("input type date not supported, fallback not implemented"));
                }
            }
        })), delay(() => append((value != null) ? singleton(new HTMLAttr(1, formatIsoDate(value_1(value)))) : empty_1(), delay(() => append((meta.Min != null) ? singleton(new HTMLAttr(119, formatIsoDate(value_1(meta.Min)))) : empty_1(), delay(() => append((meta.Max != null) ? singleton(new HTMLAttr(114, formatIsoDate(value_1(meta.Max)))) : empty_1(), delay(() => append((meta.AutoCompleteHint != null) ? singleton(new HTMLAttr(54, toString_1(value_1(meta.AutoCompleteHint)))) : empty_1(), delay(() => singleton(new DOMAttr(9, (ev) => {
            let copyOfStruct;
            if (ev.target.valueAsDate == null) {
                if (isNullOrEmpty(ev.target.value)) {
                    if ((ev.target.validity == null) ? true : ev.target.validity.valid) {
                        dispatch(void 0);
                    }
                    else {
                        dispatch(new DateValue(1, ev.target.value));
                    }
                }
                else {
                    dispatch(new DateValue(1, ev.target.value));
                }
            }
            else {
                dispatch(new DateValue(0, (copyOfStruct = fromDate(date(ev.target.valueAsDate), tupledArg[1]), addHours(copyOfStruct, 12))));
            }
        }))))))))))))))))))))))));
    }, void 0, uncurry(2, void 0), void 0, "viewInternal", "/home/vsts/work/1/s/src/Fame.Survey/Survey.UI/Components/DateInput.fs", 16);
})();

export function view(inputOptions, timeZoneOffset, meta, value, dispatch) {
    return viewInternal([inputOptions, timeZoneOffset, meta, value, dispatch]);
}

