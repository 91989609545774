import { AnsweredQuestionTypeModule_resetValue, OpenSurvey_QuestionTypeL_Z1C363501, Survey, OpenSurvey, SurveyId } from "./AnswerQuestion.fs.js";
import { SurveyTypeId } from "./CommonTypes.fs.js";
import { fold, empty } from "../Survey.UI/fable_modules/fable-library.3.7.12/List.js";
import { Optic_Set, Optic_Set_op_HatEquals_30FB2466, Optic_Map, Optic_Map_op_HatPercent_30E73038 } from "../../Fame/Optics/Aether.fs.js";

export const emptySurvey = new Survey(0, new OpenSurvey(new SurveyId(0, ""), new SurveyTypeId(0, ""), "", new Array(0), void 0, void 0, void 0, void 0, empty()));

export function openSurveyApply(openSurvey, event) {
    switch (event.tag) {
        case 1: {
            return Optic_Map_op_HatPercent_30E73038(new Optic_Map(0), OpenSurvey_QuestionTypeL_Z1C363501(event.fields[0]))(AnsweredQuestionTypeModule_resetValue)(openSurvey);
        }
        case 2: {
            return Optic_Set_op_HatEquals_30FB2466(new Optic_Set(0), OpenSurvey_QuestionTypeL_Z1C363501(event.fields[0]))(event.fields[1])(openSurvey);
        }
        default: {
            return event.fields[0];
        }
    }
}

export function apply(survey, event) {
    if (survey.tag === 0) {
        return new Survey(0, openSurveyApply(survey.fields[0], event));
    }
    else {
        return survey;
    }
}

export function surveyState(history) {
    return fold(apply, emptySurvey, history);
}

