import { Union } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { float64_type, obj_type, union_type, int32_type, string_type, lambda_type, unit_type, class_type, bool_type } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { CSSProp$reflection } from "../../Fame.Survey/Survey.UI/fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { keyValueList } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/MapUtil.js";
import { totalSeconds } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/TimeSpan.js";
import { map } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Seq.js";
import * as react from "react";
import { AnimateGroup, Animate } from "react-simple-animate";

export class Animate_Prop extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Play", "End", "Start", "Complete", "Duration", "Delay", "OnComplete", "EaseType", "SequenceIndex", "SequenceId", "Overlay"];
    }
}

export function Animate_Prop$reflection() {
    return union_type("Fable.ReactSimpleAnimate.Animate.Prop", [], Animate_Prop, () => [[["Item", bool_type]], [["Item", class_type("System.Collections.Generic.IEnumerable`1", [CSSProp$reflection()])]], [["Item", class_type("System.Collections.Generic.IEnumerable`1", [CSSProp$reflection()])]], [["Item", class_type("System.Collections.Generic.IEnumerable`1", [CSSProp$reflection()])]], [["Item", class_type("System.TimeSpan")]], [["Item", class_type("System.TimeSpan")]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", class_type("System.TimeSpan")]]]);
}

class Animate_InternalProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Play", "End", "Start", "Complete", "Duration", "Delay", "OnComplete", "EaseType", "SequenceIndex", "SequenceId", "Overlay"];
    }
}

function Animate_InternalProp$reflection() {
    return union_type("Fable.ReactSimpleAnimate.Animate.InternalProp", [], Animate_InternalProp, () => [[["Item", bool_type]], [["Item", obj_type]], [["Item", obj_type]], [["Item", obj_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", lambda_type(unit_type, unit_type)]], [["Item", string_type]], [["Item", int32_type]], [["Item", string_type]], [["Item", class_type("System.TimeSpan")]]]);
}

function Animate_toInternalProp(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return new Animate_InternalProp(1, keyValueList(_arg1.fields[0], 1));
        }
        case 2: {
            return new Animate_InternalProp(2, keyValueList(_arg1.fields[0], 1));
        }
        case 3: {
            return new Animate_InternalProp(3, keyValueList(_arg1.fields[0], 1));
        }
        case 4: {
            return new Animate_InternalProp(4, totalSeconds(_arg1.fields[0]));
        }
        case 5: {
            return new Animate_InternalProp(5, totalSeconds(_arg1.fields[0]));
        }
        case 6: {
            return new Animate_InternalProp(6, _arg1.fields[0]);
        }
        case 7: {
            return new Animate_InternalProp(7, _arg1.fields[0]);
        }
        case 8: {
            return new Animate_InternalProp(8, _arg1.fields[0]);
        }
        case 9: {
            return new Animate_InternalProp(9, _arg1.fields[0]);
        }
        case 10: {
            return new Animate_InternalProp(10, _arg1.fields[0]);
        }
        default: {
            return new Animate_InternalProp(0, _arg1.fields[0]);
        }
    }
}

export function Animate_animate(props, children) {
    const internalProps = keyValueList(map(Animate_toInternalProp, props), 1);
    return react.createElement(Animate, internalProps, ...children);
}

export class AnimateGroup_Prop extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Play"];
    }
}

export function AnimateGroup_Prop$reflection() {
    return union_type("Fable.ReactSimpleAnimate.AnimateGroup.Prop", [], AnimateGroup_Prop, () => [[["Item", bool_type]]]);
}

export function AnimateGroup_animateGroup(props, children) {
    const internalProps = keyValueList(props, 1);
    return react.createElement(AnimateGroup, internalProps, ...children);
}

