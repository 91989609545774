import { map, tryFind } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/List.js";

export function WhereL(predicate) {
    return [(list) => tryFind(predicate, list), (newItem) => ((list_1) => map((item) => {
        if (predicate(item)) {
            return newItem;
        }
        else {
            return item;
        }
    }, list_1))];
}

