import { toString, Union } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { class_type, union_type, string_type, int32_type } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { interpolate, toText } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/String.js";
import { Result_Map, Result_Bind, FSharpResult$2 } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Choice.js";
import { create, isMatch } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/RegExp.js";
import { compare } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Util.js";
import { exists } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { isDigit } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Char.js";
import { fold } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/List.js";

export class ValidationError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["MustNotBeNull", "MustNotBeEmpty", "MustNotBeLongerThan", "MustBeExactlyCharacters", "MustMatchPattern", "MustNotBeLessThan", "MustNotBeGreaterThan", "MustStartWith", "MustBeANumber", "MustContain"];
    }
}

export function ValidationError$reflection() {
    return union_type("Fame.Types.ValidationError", [], ValidationError, () => [[], [], [["max", int32_type], ["actual", int32_type]], [["expected", int32_type], ["actual", int32_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", string_type]]]);
}

export function ValidationErrorModule_toEnglish(fieldName, err) {
    const english = (err.tag === 1) ? "must not be empty" : ((err.tag === 2) ? toText(interpolate("must not be longer than %d%P() characters, but is %d%P()", [err.fields[0], err.fields[1]])) : ((err.tag === 3) ? toText(interpolate("must be %d%P() characters long, but is %d%P()", [err.fields[0], err.fields[1]])) : ((err.tag === 4) ? (`must match the pattern ${err.fields[0]}`) : ((err.tag === 5) ? (`must not be less than ${err.fields[0]}`) : ((err.tag === 6) ? (`must not be greater than ${err.fields[0]}`) : ((err.tag === 7) ? (`must start with ${err.fields[0]}`) : ((err.tag === 8) ? "must be a number" : ((err.tag === 9) ? (`must contain "${err.fields[0]}"`) : "must not be null"))))))));
    return `${fieldName}: ${english}`;
}

export class Validation {
    constructor() {
    }
}

export function Validation$reflection() {
    return class_type("Fame.Types.Validation", void 0, Validation);
}

export function Validation_get_CheckNullOrEmpty() {
    return (input) => ((input == null) ? (new FSharpResult$2(1, new ValidationError(0))) : ((input.length === 0) ? (new FSharpResult$2(1, new ValidationError(1))) : (new FSharpResult$2(0, input))));
}

export function Validation_CheckLength_Z524259A4(expected) {
    return (input) => {
        const current = input.length | 0;
        return (current !== expected) ? (new FSharpResult$2(1, new ValidationError(3, expected, current))) : (new FSharpResult$2(0, input));
    };
}

export function Validation_CheckMaxLength_Z524259A4(max) {
    return (input) => {
        const current = input.length | 0;
        return (current > max) ? (new FSharpResult$2(1, new ValidationError(2, max, current))) : (new FSharpResult$2(0, input));
    };
}

export function Validation_Matches_Z721C83C5(pattern) {
    return (input) => ((!isMatch(create(pattern), input)) ? (new FSharpResult$2(1, new ValidationError(4, pattern))) : (new FSharpResult$2(0, input)));
}

export function Validation_Matches_ZAEB317B(re) {
    return (input) => ((!isMatch(re, input)) ? (new FSharpResult$2(1, new ValidationError(4, toString(re)))) : (new FSharpResult$2(0, input)));
}

export function Validation_CheckMin_Z63A0D504(min) {
    return (input) => ((compare(input, min) < 0) ? (new FSharpResult$2(1, new ValidationError(5, toString(min)))) : (new FSharpResult$2(0, input)));
}

export function Validation_CheckMax_Z63A0D504(max) {
    return (input) => ((compare(max, input) < 0) ? (new FSharpResult$2(1, new ValidationError(6, toString(max)))) : (new FSharpResult$2(0, input)));
}

export function Validation_StartsWith_Z721C83C5(prefix) {
    return (input) => ((!(input.indexOf(prefix) === 0)) ? (new FSharpResult$2(1, new ValidationError(7, prefix))) : (new FSharpResult$2(0, input)));
}

export function Validation_get_IsNumeric() {
    return (input) => (exists((ch) => (!isDigit(ch)), input.split("")) ? (new FSharpResult$2(1, new ValidationError(8))) : (new FSharpResult$2(0, input)));
}

export function Validation_Contains_Z721C83C5(searchStr) {
    return (input) => ((!(input.indexOf(searchStr) >= 0)) ? (new FSharpResult$2(1, new ValidationError(9, searchStr))) : (new FSharpResult$2(0, input)));
}

export function Validation_CheckMultiple_53E2B31B(validators) {
    return (input) => fold((input_1, validator) => Result_Bind(validator, input_1), new FSharpResult$2(0, input), validators);
}

export function Validation_check(ctor, validators) {
    return (input) => Result_Map(ctor, fold((input_1, validator) => Result_Bind(validator, input_1), new FSharpResult$2(0, input), validators));
}

