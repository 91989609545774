import { Record } from "../Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { anonRecord_type, option_type, record_type, lambda_type, string_type, class_type } from "../Survey.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { toLocalTime } from "../Survey.UI/fable_modules/fable-library.3.7.12/DateOffset.js";
import { interpolate, printf, toText } from "../Survey.UI/fable_modules/fable-library.3.7.12/String.js";
import { map, defaultArg } from "../Survey.UI/fable_modules/fable-library.3.7.12/Option.js";

export class DateErrorResources extends Record {
    constructor(OutOfRangeMin, OutOfRangeMax, InvalidDate) {
        super();
        this.OutOfRangeMin = OutOfRangeMin;
        this.OutOfRangeMax = OutOfRangeMax;
        this.InvalidDate = InvalidDate;
    }
}

export function DateErrorResources$reflection() {
    return record_type("Fame.Survey.DateErrorResources", [], DateErrorResources, () => [["OutOfRangeMin", lambda_type(class_type("System.DateTimeOffset"), string_type)], ["OutOfRangeMax", lambda_type(class_type("System.DateTimeOffset"), string_type)], ["InvalidDate", string_type]]);
}

export class DecimalErrorResources extends Record {
    constructor(OutOfRangeMin, OutOfRangeMax, OutOfRangeStep) {
        super();
        this.OutOfRangeMin = OutOfRangeMin;
        this.OutOfRangeMax = OutOfRangeMax;
        this.OutOfRangeStep = OutOfRangeStep;
    }
}

export function DecimalErrorResources$reflection() {
    return record_type("Fame.Survey.DecimalErrorResources", [], DecimalErrorResources, () => [["OutOfRangeMin", lambda_type(class_type("System.Decimal"), string_type)], ["OutOfRangeMax", lambda_type(class_type("System.Decimal"), string_type)], ["OutOfRangeStep", lambda_type(anonRecord_type(["NearestOver", option_type(class_type("System.Decimal"))], ["NearestUnder", option_type(class_type("System.Decimal"))], ["Step", class_type("System.Decimal")]), string_type)]]);
}

export class ErrorResources extends Record {
    constructor(WrongType, MalformedTextFormat, PossibleAnswerNotFound, Date$, Decimal, InvalidFormat, InvalidEmailAddress, IsRequired, AreNotEqual) {
        super();
        this.WrongType = WrongType;
        this.MalformedTextFormat = MalformedTextFormat;
        this.PossibleAnswerNotFound = PossibleAnswerNotFound;
        this.Date = Date$;
        this.Decimal = Decimal;
        this.InvalidFormat = InvalidFormat;
        this.InvalidEmailAddress = InvalidEmailAddress;
        this.IsRequired = IsRequired;
        this.AreNotEqual = AreNotEqual;
    }
}

export function ErrorResources$reflection() {
    return record_type("Fame.Survey.ErrorResources", [], ErrorResources, () => [["WrongType", string_type], ["MalformedTextFormat", string_type], ["PossibleAnswerNotFound", string_type], ["Date", DateErrorResources$reflection()], ["Decimal", DecimalErrorResources$reflection()], ["InvalidFormat", string_type], ["InvalidEmailAddress", string_type], ["IsRequired", string_type], ["AreNotEqual", string_type]]);
}

export class Resources extends Record {
    constructor(EndpointAndIdMissing, IdMissing, HubUriMissing, Loading, SomethingWentWrong, ThankYou, YouFinished, DidYouMean, PleaseRepeat, UploadPicture, Previous, Next, Save, Finish, Errors) {
        super();
        this.EndpointAndIdMissing = EndpointAndIdMissing;
        this.IdMissing = IdMissing;
        this.HubUriMissing = HubUriMissing;
        this.Loading = Loading;
        this.SomethingWentWrong = SomethingWentWrong;
        this.ThankYou = ThankYou;
        this.YouFinished = YouFinished;
        this.DidYouMean = DidYouMean;
        this.PleaseRepeat = PleaseRepeat;
        this.UploadPicture = UploadPicture;
        this.Previous = Previous;
        this.Next = Next;
        this.Save = Save;
        this.Finish = Finish;
        this.Errors = Errors;
    }
}

export function Resources$reflection() {
    return record_type("Fame.Survey.Resources", [], Resources, () => [["EndpointAndIdMissing", string_type], ["IdMissing", string_type], ["HubUriMissing", string_type], ["Loading", string_type], ["SomethingWentWrong", string_type], ["ThankYou", string_type], ["YouFinished", string_type], ["DidYouMean", string_type], ["PleaseRepeat", string_type], ["UploadPicture", string_type], ["Previous", string_type], ["Next", string_type], ["Save", string_type], ["Finish", string_type], ["Errors", ErrorResources$reflection()]]);
}

export function ResourcesModule_english(loc) {
    let clo1, clo1_1;
    return new Resources("Neither endpoint nor survey ID and hub URI are supplied.", "Survey ID is missing.", "Hub URI is missing.", "Loading...", "Something went wrong.", "Thank you!", "You finished this survey.", "Did you mean:", "Please repeat:", "Upload picture", "Previous", "Next", "Save", "Finish", new ErrorResources("Answer type doesn\u0027t match question type", "Format string is malformed", "Possible answer ID not found", new DateErrorResources((dateTimeOffset) => {
        const dateStr = loc.FormatDate(toLocalTime(dateTimeOffset));
        return `The date must not be before ${dateStr}`;
    }, (dateTimeOffset_1) => {
        const dateStr_1 = loc.FormatDate(toLocalTime(dateTimeOffset_1));
        return `The date must not be after ${dateStr_1}`;
    }, "Invalid date"), new DecimalErrorResources((clo1 = toText(printf("The number must not be under %.2f")), clo1), (clo1_1 = toText(printf("The number must not be over %.2f")), clo1_1), (options) => {
        let option, matchValue, nearest, over, under, clo1_4;
        const nextPossible = defaultArg((option = ((matchValue = [options.NearestUnder, options.NearestOver], (matchValue[0] == null) ? ((matchValue[1] == null) ? (void 0) : ((nearest = matchValue[1], toText(printf("value is %.2f"))(nearest)))) : ((matchValue[1] == null) ? ((nearest = matchValue[0], toText(printf("value is %.2f"))(nearest))) : ((over = matchValue[1], (under = matchValue[0], toText(printf("values are %.2f and %.2f"))(under)(over))))))), map((clo1_4 = toText(printf(", the nearest possible %s")), clo1_4), option)), "");
        return toText(printf("Please adhere to the step size of %.2f%s"))(options.Step)(nextPossible);
    }), "Invalid format, please follow the following", "Invalid email address", "This field is required", "These fields need to be the same"));
}

export function ResourcesModule_german(loc) {
    let clo1, clo1_1;
    return new Resources("Kein Endpunkt oder keine Fragebogen-ID mit Hub-URI angegeben.", "Die Fragebogen-ID fehlt.", "Die Hub-URI fehlt.", "Lade...", "Etwas ist schief gelaufen.", "Danke!", "Sie haben diesen Fragebogen beendet.", "Meinten Sie:", "Bitte wiederholen:", "Bild hochladen", "Zurück", "Weiter", "Speichern", "Beenden", new ErrorResources("Antworttyp stimmt nicht mit Fragetyp überein", "Formatstring ist fehlerhaft", "Mögliche Antwort-ID nicht gefunden", new DateErrorResources((dateTimeOffset) => {
        const dateStr = loc.FormatDate(toLocalTime(dateTimeOffset));
        return `Das Datum darf nicht vor dem ${dateStr} liegen`;
    }, (dateTimeOffset_1) => {
        const dateStr_1 = loc.FormatDate(toLocalTime(dateTimeOffset_1));
        return `Das Datum darf nicht nach dem ${dateStr_1} liegen`;
    }, "Ungültiges Datum"), new DecimalErrorResources((clo1 = toText(printf("Die Zahl darf nicht unter %.2f sein")), clo1), (clo1_1 = toText(printf("Die Zahl darf nicht über %.2f sein")), clo1_1), (options) => {
        let option, matchValue, nearest, over, under, clo1_2;
        return toText(interpolate("Bitte folgen Sie der Schrittgröße von %.2f%P()%s%P()", [options.Step, defaultArg((option = ((matchValue = [options.NearestUnder, options.NearestOver], (matchValue[0] == null) ? ((matchValue[1] == null) ? (void 0) : ((nearest = matchValue[1], toText(interpolate("der nähste passende Wert wäre %.2f%P()", [nearest]))))) : ((matchValue[1] == null) ? ((nearest = matchValue[0], toText(interpolate("der nähste passende Wert wäre %.2f%P()", [nearest])))) : ((over = matchValue[1], (under = matchValue[0], toText(interpolate("die nähsten passenden Werte wären %.2f%P() und %.2f%P()", [under, over])))))))), map((clo1_2 = toText(printf(", %s")), clo1_2), option)), "")]));
    }), "Ungültiges Format, bitte beachten Sie folgendes", "Ungültige E-Mail-Adresse", "Dieses Feld ist erforderlich", "Diese Felder müssen gleich sein"));
}

export function ResourcesModule_get(locale) {
    switch (locale.tag) {
        case 2: {
            return ResourcesModule_german;
        }
        default: {
            return ResourcesModule_english;
        }
    }
}

