import { Record, toString, Union } from "../Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { array_type, record_type, int32_type, list_type, tuple_type, bool_type, option_type, union_type, string_type, class_type } from "../Survey.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { Color$reflection, SurveyTypeId$reflection, Document$$reflection, QuestionId$reflection, UploadType$reflection, MultipleChoiceType$reflection, PossibleAnswer$reflection, SingleChoiceType$reflection, DecimalMetaData$reflection, DateMetaData$reflection, TextMetaData$reflection, TextFormat$reflection, Longitude$reflection, Latitude$reflection, PossibleAnswerId$reflection } from "./CommonTypes.fs.js";
import { trim as trim_1, toOption, ensureEndsWith } from "../../Fame/Core/String.fs.js";
import { bind, defaultArg } from "../Survey.UI/fable_modules/fable-library.3.7.12/Option.js";
import { map, tryPick, filter, cons, singleton, empty, isEmpty } from "../Survey.UI/fable_modules/fable-library.3.7.12/List.js";
import { equals } from "../Survey.UI/fable_modules/fable-library.3.7.12/Util.js";
import { Compose_Prism, Compose_Prism_op_GreaterQmarkGreater_4C84C7CB, Optic_Set_op_HatEquals_2170E4F5, Optic_Set, Optic_Set_op_HatEquals_30FB2466, Optic_Get, Optic_Get_op_HatDot_30FDEAF2, Compose_Lens, Compose_Lens_op_GreaterMinusGreater_206F9379 } from "../../Fame/Optics/Aether.fs.js";
import { WhereL } from "../../Fame/Optics/Optics.fs.js";

export class DateValue extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Valid", "Invalid"];
    }
}

export function DateValue$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.DateValue", [], DateValue, () => [[["Item", class_type("System.DateTimeOffset")]], [["Item", string_type]]]);
}

export function DateValueModule_tryGetDate(_arg1) {
    if (_arg1.tag === 1) {
        return void 0;
    }
    else {
        return _arg1.fields[0];
    }
}

export class AnswerValue extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["TextValue", "FormattedTextValue", "DateValue", "DecimalValue", "SingleChoiceValue", "MultipleChoiceValue", "UploadValue", "GeoLocationValue", "EmailValue", "DualValue"];
    }
}

export function AnswerValue$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.AnswerValue", [], AnswerValue, () => [[["Item", option_type(string_type)]], [["Item", option_type(string_type)]], [["Item", option_type(DateValue$reflection())]], [["Item", option_type(class_type("System.Decimal"))]], [["Item", option_type(PossibleAnswerId$reflection())]], [["Item", option_type(tuple_type(PossibleAnswerId$reflection(), bool_type))]], [["Item", option_type(class_type("System.Uri"))]], [["Item", option_type(tuple_type(Latitude$reflection(), Longitude$reflection()))]], [["Item", option_type(string_type)]], [["Item1", option_type(string_type)], ["Item2", option_type(string_type)]]]);
}

export class AnswerValidationInternalError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["WrongType", "MalformedTextFormat", "PossibleAnswerNotFound"];
    }
}

export function AnswerValidationInternalError$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.AnswerValidationInternalError", [], AnswerValidationInternalError, () => [[], [["Item", string_type]], [["Item", PossibleAnswerId$reflection()]]]);
}

export function AnswerValidationInternalErrorModule_toHumanReadable(res, _arg1) {
    switch (_arg1.tag) {
        case 1: {
            return (res.MalformedTextFormat + ": ") + _arg1.fields[0];
        }
        case 2: {
            return (res.PossibleAnswerNotFound + ": ") + toString(_arg1.fields[0]);
        }
        default: {
            return res.WrongType;
        }
    }
}

export class DateError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OutOfRangeMin", "OutOfRangeMax", "InvalidDate"];
    }
}

export function DateError$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.DateError", [], DateError, () => [[["Item", class_type("System.DateTimeOffset")]], [["Item", class_type("System.DateTimeOffset")]], []]);
}

export class DecimalError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OutOfRangeMin", "OutOfRangeMax", "OutOfRangeStep"];
    }
}

export function DecimalError$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.DecimalError", [], DecimalError, () => [[["Item", class_type("System.Decimal")]], [["Item", class_type("System.Decimal")]], [["step", class_type("System.Decimal")], ["nearestUnder", option_type(class_type("System.Decimal"))], ["nearestOver", option_type(class_type("System.Decimal"))]]]);
}

export class AnswerValidationError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["InternalError", "InvalidFormat", "DateError", "DecimalError", "InvalidEmailAddress", "IsRequired", "AreNotEqual"];
    }
}

export function AnswerValidationError$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.AnswerValidationError", [], AnswerValidationError, () => [[["Item", AnswerValidationInternalError$reflection()]], [["Item", TextFormat$reflection()]], [["Item", DateError$reflection()]], [["Item", DecimalError$reflection()]], [], [], []]);
}

export function AnswerValidationErrorModule_toHumanReadable(res) {
    return (arg) => {
        let _arg1, options, dateErr, decimalErr;
        return ensureEndsWith(".", (_arg1 = arg, (_arg1.tag === 1) ? ((options = _arg1.fields[0], defaultArg(options.InvalidFormatMessage, (res.InvalidFormat + ": ") + options.Format))) : ((_arg1.tag === 2) ? ((dateErr = _arg1.fields[0], (dateErr.tag === 1) ? res.Date.OutOfRangeMax(dateErr.fields[0]) : ((dateErr.tag === 2) ? res.Date.InvalidDate : res.Date.OutOfRangeMin(dateErr.fields[0])))) : ((_arg1.tag === 3) ? ((decimalErr = _arg1.fields[0], (decimalErr.tag === 1) ? res.Decimal.OutOfRangeMax(decimalErr.fields[0]) : ((decimalErr.tag === 2) ? res.Decimal.OutOfRangeStep({
            NearestOver: decimalErr.fields[2],
            NearestUnder: decimalErr.fields[1],
            Step: decimalErr.fields[0],
        }) : res.Decimal.OutOfRangeMin(decimalErr.fields[0])))) : ((_arg1.tag === 4) ? res.InvalidEmailAddress : ((_arg1.tag === 5) ? res.IsRequired : ((_arg1.tag === 6) ? res.AreNotEqual : AnswerValidationInternalErrorModule_toHumanReadable(res, _arg1.fields[0]))))))));
    };
}

export class AnswerState$1 extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ValidValue", "InvalidValue", "NotAnswered"];
    }
}

export function AnswerState$1$reflection(gen0) {
    return union_type("Fame.Survey.AnswerQuestion.AnswerState`1", [gen0], AnswerState$1, () => [[["Item", option_type(gen0)]], [["Item1", option_type(gen0)], ["Item2", list_type(AnswerValidationError$reflection())]], []]);
}

export function AnswerState_ofOption(errors, value) {
    if (isEmpty(errors)) {
        return new AnswerState$1(0, value);
    }
    else {
        return new AnswerState$1(1, value, errors);
    }
}

export function AnswerState_hasValidValue(_arg1) {
    if (_arg1.tag === 0) {
        return true;
    }
    else {
        return false;
    }
}

export function AnswerState_value(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return _arg1.fields[0];
        }
        case 2: {
            return void 0;
        }
        default: {
            return _arg1.fields[0];
        }
    }
}

export function AnswerState_$007CValue$007C_$007C() {
    return AnswerState_value;
}

export function AnswerState_errors(_arg1) {
    switch (_arg1.tag) {
        case 2: {
            return empty();
        }
        case 1: {
            return _arg1.fields[1];
        }
        default: {
            return empty();
        }
    }
}

export class Weighting extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Weighting"];
    }
}

export function Weighting$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.Weighting", [], Weighting, () => [[["Item", int32_type]]]);
}

export class DualAnsweredQuestionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DualText", "DualFormattedText", "DualEmail"];
    }
}

export function DualAnsweredQuestionType$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.DualAnsweredQuestionType", [], DualAnsweredQuestionType, () => [[["Item1", TextMetaData$reflection()], ["Item2", AnswerState$1$reflection(tuple_type(option_type(string_type), option_type(string_type)))]], [["Item1", TextMetaData$reflection()], ["Item2", TextFormat$reflection()], ["Item3", AnswerState$1$reflection(tuple_type(option_type(string_type), option_type(string_type)))]], [["Item", AnswerState$1$reflection(tuple_type(option_type(string_type), option_type(string_type)))]]]);
}

export function DualAnsweredQuestionTypeModule_state(_arg1) {
    switch (_arg1.tag) {
        case 1: {
            return _arg1.fields[2];
        }
        case 2: {
            return _arg1.fields[0];
        }
        default: {
            return _arg1.fields[1];
        }
    }
}

export class AnsweredQuestionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Text", "FormattedText", "Date", "Decimal", "SingleChoice", "MultipleChoice", "Upload", "GeoLocation", "Email", "Dual"];
    }
}

export function AnsweredQuestionType$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.AnsweredQuestionType", [], AnsweredQuestionType, () => [[["Item1", TextMetaData$reflection()], ["Item2", AnswerState$1$reflection(string_type)]], [["Item1", TextMetaData$reflection()], ["Item2", TextFormat$reflection()], ["Item3", AnswerState$1$reflection(string_type)]], [["Item1", DateMetaData$reflection()], ["Item2", AnswerState$1$reflection(class_type("System.DateTimeOffset"))]], [["Item1", DecimalMetaData$reflection()], ["Item2", AnswerState$1$reflection(class_type("System.Decimal"))]], [["Item1", SingleChoiceType$reflection()], ["Item2", list_type(tuple_type(PossibleAnswer$reflection(), class_type("System.Decimal")))], ["Item3", Weighting$reflection()], ["Item4", AnswerState$1$reflection(PossibleAnswerId$reflection())]], [["Item1", MultipleChoiceType$reflection()], ["Item2", list_type(PossibleAnswer$reflection())], ["Item3", AnswerState$1$reflection(list_type(PossibleAnswerId$reflection()))]], [["Item1", UploadType$reflection()], ["Item2", AnswerState$1$reflection(class_type("System.Uri"))]], [["Item", AnswerState$1$reflection(tuple_type(Latitude$reflection(), Longitude$reflection()))]], [["Item", AnswerState$1$reflection(string_type)]], [["Item", DualAnsweredQuestionType$reflection()]]]);
}

export function AnsweredQuestionTypeModule_hasValidValue(typ) {
    switch (typ.tag) {
        case 1: {
            return AnswerState_hasValidValue(typ.fields[2]);
        }
        case 2: {
            return AnswerState_hasValidValue(typ.fields[1]);
        }
        case 3: {
            return AnswerState_hasValidValue(typ.fields[1]);
        }
        case 4: {
            return AnswerState_hasValidValue(typ.fields[3]);
        }
        case 5: {
            return AnswerState_hasValidValue(typ.fields[2]);
        }
        case 6: {
            return AnswerState_hasValidValue(typ.fields[1]);
        }
        case 7: {
            return AnswerState_hasValidValue(typ.fields[0]);
        }
        case 8: {
            return AnswerState_hasValidValue(typ.fields[0]);
        }
        case 9: {
            const dualType = typ.fields[0];
            switch (dualType.tag) {
                case 1: {
                    return AnswerState_hasValidValue(dualType.fields[2]);
                }
                case 2: {
                    return AnswerState_hasValidValue(dualType.fields[0]);
                }
                default: {
                    return AnswerState_hasValidValue(dualType.fields[1]);
                }
            }
        }
        default: {
            return AnswerState_hasValidValue(typ.fields[1]);
        }
    }
}

export function AnsweredQuestionTypeModule_errors(typ) {
    switch (typ.tag) {
        case 1: {
            return AnswerState_errors(typ.fields[2]);
        }
        case 2: {
            return AnswerState_errors(typ.fields[1]);
        }
        case 3: {
            return AnswerState_errors(typ.fields[1]);
        }
        case 4: {
            return AnswerState_errors(typ.fields[3]);
        }
        case 5: {
            return AnswerState_errors(typ.fields[2]);
        }
        case 6: {
            return AnswerState_errors(typ.fields[1]);
        }
        case 7: {
            return AnswerState_errors(typ.fields[0]);
        }
        case 8: {
            return AnswerState_errors(typ.fields[0]);
        }
        case 9: {
            const dualType = typ.fields[0];
            switch (dualType.tag) {
                case 1: {
                    return AnswerState_errors(dualType.fields[2]);
                }
                case 2: {
                    return AnswerState_errors(dualType.fields[0]);
                }
                default: {
                    return AnswerState_errors(dualType.fields[1]);
                }
            }
        }
        default: {
            return AnswerState_errors(typ.fields[1]);
        }
    }
}

export function AnsweredQuestionTypeModule_withValue(answerValue, errors, typ) {
    let matchValue_2;
    const answerOfOption = (v) => AnswerState_ofOption(errors, v);
    const trim = (option) => bind((arg) => toOption(trim_1(arg)), option);
    const matchValue = [typ, answerValue];
    let pattern_matching_result, meta, v_1, format, meta_1, v_2, meta_2, v_3, meta_3, v_4, possibleAnswers, typ_1, v_5, weighting, possibleAnswers_1, typ_2, answerId, isSelected, oldValue, possibleAnswers_2, typ_3, typ_4, v_7, v_8, v_9, dualType, firstValue, secondValue;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 1;
            format = matchValue[0].fields[1];
            meta_1 = matchValue[0].fields[0];
            v_2 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 2;
            meta_2 = matchValue[0].fields[0];
            v_3 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 3) {
        if (matchValue[1].tag === 3) {
            pattern_matching_result = 3;
            meta_3 = matchValue[0].fields[0];
            v_4 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 4) {
        if (matchValue[1].tag === 4) {
            pattern_matching_result = 4;
            possibleAnswers = matchValue[0].fields[1];
            typ_1 = matchValue[0].fields[0];
            v_5 = matchValue[1].fields[0];
            weighting = matchValue[0].fields[2];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 5) {
        if (matchValue[1].tag === 5) {
            if (matchValue[1].fields[0] != null) {
                pattern_matching_result = 6;
                answerId = matchValue[1].fields[0][0];
                isSelected = matchValue[1].fields[0][1];
                oldValue = matchValue[0].fields[2];
                possibleAnswers_2 = matchValue[0].fields[1];
                typ_3 = matchValue[0].fields[0];
            }
            else {
                pattern_matching_result = 5;
                possibleAnswers_1 = matchValue[0].fields[1];
                typ_2 = matchValue[0].fields[0];
            }
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 6) {
        if (matchValue[1].tag === 6) {
            pattern_matching_result = 7;
            typ_4 = matchValue[0].fields[0];
            v_7 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 7) {
        if (matchValue[1].tag === 7) {
            pattern_matching_result = 8;
            v_8 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 8) {
        if (matchValue[1].tag === 8) {
            pattern_matching_result = 9;
            v_9 = matchValue[1].fields[0];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[0].tag === 9) {
        if (matchValue[1].tag === 9) {
            pattern_matching_result = 10;
            dualType = matchValue[0].fields[0];
            firstValue = matchValue[1].fields[0];
            secondValue = matchValue[1].fields[1];
        }
        else {
            pattern_matching_result = 11;
        }
    }
    else if (matchValue[1].tag === 0) {
        pattern_matching_result = 0;
        meta = matchValue[0].fields[0];
        v_1 = matchValue[1].fields[0];
    }
    else {
        pattern_matching_result = 11;
    }
    switch (pattern_matching_result) {
        case 0: {
            return new AnsweredQuestionType(0, meta, answerOfOption(trim(v_1)));
        }
        case 1: {
            return new AnsweredQuestionType(1, meta_1, format, answerOfOption(trim(v_2)));
        }
        case 2: {
            return new AnsweredQuestionType(2, meta_2, answerOfOption(bind(DateValueModule_tryGetDate, v_3)));
        }
        case 3: {
            return new AnsweredQuestionType(3, meta_3, answerOfOption(v_4));
        }
        case 4: {
            return new AnsweredQuestionType(4, typ_1, possibleAnswers, weighting, answerOfOption(v_5));
        }
        case 5: {
            return new AnsweredQuestionType(5, typ_2, possibleAnswers_1, answerOfOption(void 0));
        }
        case 6: {
            let newSelected;
            let _arg1_1;
            const matchValue_1 = [isSelected, AnswerState_value(oldValue)];
            _arg1_1 = (matchValue_1[0] ? ((matchValue_1[1] == null) ? singleton(answerId) : cons(answerId, matchValue_1[1])) : ((matchValue_1[1] == null) ? empty() : filter((y) => (!equals(answerId, y)), matchValue_1[1])));
            newSelected = (isEmpty(_arg1_1) ? (void 0) : _arg1_1);
            return new AnsweredQuestionType(5, typ_3, possibleAnswers_2, isEmpty(errors) ? (new AnswerState$1(0, newSelected)) : (new AnswerState$1(1, newSelected, errors)));
        }
        case 7: {
            return new AnsweredQuestionType(6, typ_4, answerOfOption(v_7));
        }
        case 8: {
            return new AnsweredQuestionType(7, answerOfOption(v_8));
        }
        case 9: {
            return new AnsweredQuestionType(8, answerOfOption(v_9));
        }
        case 10: {
            const answer = answerOfOption((matchValue_2 = [trim(firstValue), trim(secondValue)], (matchValue_2[0] == null) ? ((matchValue_2[1] == null) ? (void 0) : [matchValue_2[0], matchValue_2[1]]) : [matchValue_2[0], matchValue_2[1]]));
            return new AnsweredQuestionType(9, (dualType.tag === 1) ? (new DualAnsweredQuestionType(1, dualType.fields[0], dualType.fields[1], answer)) : ((dualType.tag === 2) ? (new DualAnsweredQuestionType(2, answer)) : (new DualAnsweredQuestionType(0, dualType.fields[0], answer))));
        }
        case 11: {
            return typ;
        }
    }
}

export function AnsweredQuestionTypeModule_resetValue(typ) {
    switch (typ.tag) {
        case 1: {
            return new AnsweredQuestionType(1, typ.fields[0], typ.fields[1], new AnswerState$1(2));
        }
        case 2: {
            return new AnsweredQuestionType(2, typ.fields[0], new AnswerState$1(2));
        }
        case 3: {
            return new AnsweredQuestionType(3, typ.fields[0], new AnswerState$1(2));
        }
        case 4: {
            return new AnsweredQuestionType(4, typ.fields[0], typ.fields[1], typ.fields[2], new AnswerState$1(2));
        }
        case 5: {
            return new AnsweredQuestionType(5, typ.fields[0], typ.fields[1], new AnswerState$1(2));
        }
        case 6: {
            return new AnsweredQuestionType(6, typ.fields[0], new AnswerState$1(2));
        }
        case 7: {
            return new AnsweredQuestionType(7, new AnswerState$1(2));
        }
        case 8: {
            return new AnsweredQuestionType(8, new AnswerState$1(2));
        }
        case 9: {
            const dualType = typ.fields[0];
            return new AnsweredQuestionType(9, (dualType.tag === 1) ? (new DualAnsweredQuestionType(1, dualType.fields[0], dualType.fields[1], new AnswerState$1(2))) : ((dualType.tag === 2) ? (new DualAnsweredQuestionType(2, new AnswerState$1(2))) : (new DualAnsweredQuestionType(0, dualType.fields[0], new AnswerState$1(2)))));
        }
        default: {
            return new AnsweredQuestionType(0, typ.fields[0], new AnswerState$1(2));
        }
    }
}

export class LegacyQuestionId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["LegacyQuestionId"];
    }
    toString() {
        const this$ = this;
        return this$.fields[0];
    }
}

export function LegacyQuestionId$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.LegacyQuestionId", [], LegacyQuestionId, () => [[["Item", string_type]]]);
}

export class AnsweredQuestion extends Record {
    constructor(QuestionId, LegacyQuestionId, Identifier, Title, Description, HasDangerHighlight, Warning, Document$, IsRequired, Type) {
        super();
        this.QuestionId = QuestionId;
        this.LegacyQuestionId = LegacyQuestionId;
        this.Identifier = Identifier;
        this.Title = Title;
        this.Description = Description;
        this.HasDangerHighlight = HasDangerHighlight;
        this.Warning = Warning;
        this.Document = Document$;
        this.IsRequired = IsRequired;
        this.Type = Type;
    }
}

export function AnsweredQuestion$reflection() {
    return record_type("Fame.Survey.AnswerQuestion.AnsweredQuestion", [], AnsweredQuestion, () => [["QuestionId", QuestionId$reflection()], ["LegacyQuestionId", option_type(LegacyQuestionId$reflection())], ["Identifier", string_type], ["Title", string_type], ["Description", option_type(string_type)], ["HasDangerHighlight", bool_type], ["Warning", option_type(string_type)], ["Document", option_type(Document$$reflection())], ["IsRequired", bool_type], ["Type", AnsweredQuestionType$reflection()]]);
}

export function AnsweredQuestion_get_TypeL() {
    return [(a) => a.Type, (b) => ((a_1) => (new AnsweredQuestion(a_1.QuestionId, a_1.LegacyQuestionId, a_1.Identifier, a_1.Title, a_1.Description, a_1.HasDangerHighlight, a_1.Warning, a_1.Document, a_1.IsRequired, b)))];
}

export class SectionId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SectionId"];
    }
    toString() {
        const this$ = this;
        return this$.fields[0];
    }
}

export function SectionId$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.SectionId", [], SectionId, () => [[["Item", string_type]]]);
}

export class Section extends Record {
    constructor(Id, Title, Questions) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Questions = Questions;
    }
}

export function Section$reflection() {
    return record_type("Fame.Survey.AnswerQuestion.Section", [], Section, () => [["Id", SectionId$reflection()], ["Title", string_type], ["Questions", list_type(AnsweredQuestion$reflection())]]);
}

export function Section_get_QuestionsL() {
    return [(a) => a.Questions, (b) => ((a_1) => (new Section(a_1.Id, a_1.Title, b)))];
}

export function Section_QuestionL_Z1C363501(questionId) {
    const l = Section_get_QuestionsL();
    return Compose_Lens_op_GreaterMinusGreater_206F9379(new Compose_Lens(0), WhereL((q) => equals(q.QuestionId, questionId)))(l);
}

export class SurveyId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SurveyId"];
    }
    toString() {
        const this$ = this;
        return this$.fields[0];
    }
}

export function SurveyId$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.SurveyId", [], SurveyId, () => [[["Item", string_type]]]);
}

export class RatingRange extends Record {
    constructor(Min, Max) {
        super();
        this.Min = (Min | 0);
        this.Max = (Max | 0);
    }
}

export function RatingRange$reflection() {
    return record_type("Fame.Survey.AnswerQuestion.RatingRange", [], RatingRange, () => [["Min", int32_type], ["Max", int32_type]]);
}

export class OpenSurvey extends Record {
    constructor(Id, SurveyTypeId, Title, Subtitles, Color, RatingRange, SaveButtonTitle, FinishButtonTitle, Sections) {
        super();
        this.Id = Id;
        this.SurveyTypeId = SurveyTypeId;
        this.Title = Title;
        this.Subtitles = Subtitles;
        this.Color = Color;
        this.RatingRange = RatingRange;
        this.SaveButtonTitle = SaveButtonTitle;
        this.FinishButtonTitle = FinishButtonTitle;
        this.Sections = Sections;
    }
}

export function OpenSurvey$reflection() {
    return record_type("Fame.Survey.AnswerQuestion.OpenSurvey", [], OpenSurvey, () => [["Id", SurveyId$reflection()], ["SurveyTypeId", SurveyTypeId$reflection()], ["Title", string_type], ["Subtitles", array_type(string_type)], ["Color", option_type(Color$reflection())], ["RatingRange", option_type(RatingRange$reflection())], ["SaveButtonTitle", option_type(string_type)], ["FinishButtonTitle", option_type(string_type)], ["Sections", list_type(Section$reflection())]]);
}

export function OpenSurvey_get_SectionsL() {
    return [(a) => a.Sections, (b) => ((a_1) => (new OpenSurvey(a_1.Id, a_1.SurveyTypeId, a_1.Title, a_1.Subtitles, a_1.Color, a_1.RatingRange, a_1.SaveButtonTitle, a_1.FinishButtonTitle, b)))];
}

export function OpenSurvey_SectionL_21B2F44C(id) {
    return WhereL((s) => equals(s.Id, id));
}

export function OpenSurvey_QuestionL_Z1C363501(questionId) {
    const questionL = Section_QuestionL_Z1C363501(questionId);
    return [(survey) => tryPick((target) => Optic_Get_op_HatDot_30FDEAF2(new Optic_Get(0), questionL)(target), survey.Sections), (newQuestion) => ((survey_1) => {
        const sections = map(Optic_Set_op_HatEquals_30FB2466(new Optic_Set(0), questionL)(newQuestion), survey_1.Sections);
        return Optic_Set_op_HatEquals_2170E4F5(new Optic_Set(0), OpenSurvey_get_SectionsL())(sections)(survey_1);
    })];
}

export function OpenSurvey_QuestionTypeL_Z1C363501(questionId) {
    const p = OpenSurvey_QuestionL_Z1C363501(questionId);
    return Compose_Prism_op_GreaterQmarkGreater_4C84C7CB(new Compose_Prism(0), AnsweredQuestion_get_TypeL())(p);
}

export class FinishedSurvey extends Record {
    constructor(Title, Description, Link) {
        super();
        this.Title = Title;
        this.Description = Description;
        this.Link = Link;
    }
}

export function FinishedSurvey$reflection() {
    return record_type("Fame.Survey.AnswerQuestion.FinishedSurvey", [], FinishedSurvey, () => [["Title", option_type(string_type)], ["Description", option_type(string_type)], ["Link", option_type(tuple_type(string_type, class_type("System.Uri")))]]);
}

export class Survey extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["OpenSurvey", "FinishedSurvey"];
    }
}

export function Survey$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.Survey", [], Survey, () => [[["Item", OpenSurvey$reflection()]], [["Item", FinishedSurvey$reflection()]]]);
}

export class AnswerEvent extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SurveyCreated", "QuestionReset", "QuestionAnswered"];
    }
}

export function AnswerEvent$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.AnswerEvent", [], AnswerEvent, () => [[["Item", OpenSurvey$reflection()]], [["Item", QuestionId$reflection()]], [["Item1", QuestionId$reflection()], ["Item2", AnsweredQuestionType$reflection()]]]);
}

export class AnswerError extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["QuestionDoesntExist", "AnswerInternalError"];
    }
}

export function AnswerError$reflection() {
    return union_type("Fame.Survey.AnswerQuestion.AnswerError", [], AnswerError, () => [[], [["Item", AnswerValidationInternalError$reflection()]]]);
}

