import { Optic_Get, Optic_Get_op_HatDot_30FDEAF2 } from "../../Fame/Optics/Aether.fs.js";
import { AnsweredQuestionTypeModule_withValue, OpenSurvey_QuestionL_Z1C363501, AnswerError, AnswerEvent, OpenSurvey_QuestionTypeL_Z1C363501 } from "./AnswerQuestion.fs.js";
import { head, isEmpty, choose, singleton } from "../Survey.UI/fable_modules/fable-library.3.7.12/List.js";
import { FSharpResult$2 } from "../Survey.UI/fable_modules/fable-library.3.7.12/Choice.js";

export function resetQuestion(survey, questionId) {
    const _arg1 = Optic_Get_op_HatDot_30FDEAF2(new Optic_Get(0), OpenSurvey_QuestionTypeL_Z1C363501(questionId))(survey);
    if (_arg1 != null) {
        return new FSharpResult$2(0, singleton(new AnswerEvent(1, questionId)));
    }
    else {
        return new FSharpResult$2(1, new AnswerError(0));
    }
}

export function answerQuestion(validateAnswer, survey, questionId, answerValue) {
    const _arg1 = Optic_Get_op_HatDot_30FDEAF2(new Optic_Get(0), OpenSurvey_QuestionL_Z1C363501(questionId))(survey);
    if (_arg1 != null) {
        const question = _arg1;
        const errors = validateAnswer(question, answerValue);
        const _arg3 = choose((_arg2) => {
            if (_arg2.tag === 0) {
                return _arg2.fields[0];
            }
            else {
                return void 0;
            }
        }, errors);
        if (isEmpty(_arg3)) {
            return new FSharpResult$2(0, singleton(new AnswerEvent(2, questionId, AnsweredQuestionTypeModule_withValue(answerValue, errors, question.Type))));
        }
        else {
            return new FSharpResult$2(1, new AnswerError(1, head(_arg3)));
        }
    }
    else {
        return new FSharpResult$2(1, new AnswerError(0));
    }
}

