import { toString, Union } from "../Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { QuestionId$reflection } from "./CommonTypes.fs.js";
import { AnswerEvent$reflection, AnswerValue$reflection } from "./AnswerQuestion.fs.js";
import { union_type } from "../Survey.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { retn, returnError, bind } from "../Survey.UI/fable_modules/FsToolkit.ErrorHandling.2.13.0/AsyncResult.fs.js";
import { Result_Map, Result_MapError } from "../Survey.UI/fable_modules/fable-library.3.7.12/Choice.js";
import { empty, map } from "../Survey.UI/fable_modules/fable-library.3.7.12/List.js";
import { answerQuestion } from "./AnswerQuestion.Behaviour.fs.js";
import { validateAnswer } from "./AnswerQuestion.Validation.fs.js";
import { singleton } from "../Survey.UI/fable_modules/fable-library.3.7.12/AsyncBuilder.js";

export class SurveyCommand extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["AnswerQuestion"];
    }
}

export function SurveyCommand$reflection() {
    return union_type("Fame.Survey.Api.SurveyCommand", [], SurveyCommand, () => [[["Item1", QuestionId$reflection()], ["Item2", AnswerValue$reflection()]]]);
}

export class SurveyOperation extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Event", "Command"];
    }
}

export function SurveyOperation$reflection() {
    return union_type("Fame.Survey.Api.SurveyOperation", [], SurveyOperation, () => [[["Item", AnswerEvent$reflection()]], [["Item", SurveyCommand$reflection()]]]);
}

export function execute(services, operation) {
    if (operation.tag === 1) {
        return bind((_arg1) => {
            if (_arg1.tag === 0) {
                const arg00 = Result_MapError(toString, Result_Map((list) => map((arg0) => (new SurveyOperation(0, arg0)), list), answerQuestion(validateAnswer, _arg1.fields[0], operation.fields[0].fields[0], operation.fields[0].fields[1])));
                return singleton.Return(arg00);
            }
            else {
                return returnError("Cannot execute AnswerQuestion on a FinishedSurvey");
            }
        }, services.LoadSurvey());
    }
    else {
        return retn(empty());
    }
}

