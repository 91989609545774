import { filter, replace as replace_1, split as split_1, endsWith, padLeft as padLeft_1, padRight as padRight_1, substring, isNullOrEmpty, join } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/String.js";
import { toArray, empty as empty_1, fold, truncate as truncate_1, skip as skip_1, reverse } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { ofSeq, splitAt as splitAt_1, empty, singleton, ofArray, append, isEmpty } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/List.js";
import { tryParse } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Int32.js";
import { FSharpRef } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { replace as replace_2 } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/RegExp.js";
import { isLetterOrDigit } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Char.js";
import { isNotNull } from "./Core.fs.js";

export function concatNewLine(lines) {
    return join("\n", lines);
}

export const isNotNullOrEmpty = (arg) => (!isNullOrEmpty(arg));

export function ofNullable(input) {
    if (isNullOrEmpty(input)) {
        return void 0;
    }
    else {
        return input;
    }
}

export function toOption(input) {
    if (isNullOrEmpty(input)) {
        return void 0;
    }
    else {
        return input;
    }
}

export function defaultValue(def, input) {
    if (isNullOrEmpty(input)) {
        return def;
    }
    else {
        return input;
    }
}

export function rev(input) {
    return join("", reverse(input.split("")));
}

export function skip(n, input) {
    if (isNullOrEmpty(input)) {
        return input;
    }
    else {
        return join("", skip_1(n, input.split("")));
    }
}

export function truncate(num, input) {
    if (isNullOrEmpty(input) ? true : (input.length <= num)) {
        return input;
    }
    else {
        return join("", truncate_1(num, input.split("")));
    }
}

export function trySubString(start, length, str) {
    if ((str == null) ? true : (str.length < (start + length))) {
        return void 0;
    }
    else {
        return substring(str, start, length);
    }
}

export function trySubStringFrom(start, str) {
    if ((str == null) ? true : (str.length < start)) {
        return void 0;
    }
    else {
        return substring(str, start);
    }
}

export function subStringFrom(start, str) {
    return substring(str, start);
}

export function padRight(length, ch, input) {
    return padRight_1(input, length, ch);
}

export function padLeft(length, ch, input) {
    return padLeft_1(input, length, ch);
}

export function ensureEndsWith(ending, input) {
    let input_1;
    if ((input_1 = input, (input_1 == null) ? false : endsWith(input_1, ending))) {
        return input;
    }
    else {
        return defaultValue("", input) + ending;
    }
}

export function pascalToHumanCase(input) {
    return join("", fold((result, ch) => {
        const s = ch;
        return ((s === s.toUpperCase()) && (!isEmpty(result))) ? append(result, ofArray([" ", ch])) : append(result, singleton(ch));
    }, empty(), input.split("")));
}

export function split(separators, str) {
    if (isNullOrEmpty(str)) {
        return empty_1();
    }
    else {
        return split_1(str, toArray(separators));
    }
}

export function splitStr(separator, str) {
    if (isNullOrEmpty(str)) {
        return empty_1();
    }
    else {
        return split_1(str, toArray(separator), null, 0);
    }
}

export function splitAt(position, input) {
    if ((input == null) ? true : (input.length < position)) {
        return void 0;
    }
    else {
        const parts = splitAt_1(position, ofSeq(input.split("")));
        return [join("", parts[0]), join("", parts[1])];
    }
}

export function trim(input) {
    if (isNullOrEmpty(input)) {
        return input;
    }
    else {
        return input.trim();
    }
}

export function stringToInt(str) {
    let matchValue;
    let outArg = 0;
    matchValue = [tryParse(str, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg];
    if (matchValue[0]) {
        return matchValue[1];
    }
    else {
        return void 0;
    }
}

export function replace(oldValue, newValue, input) {
    if (input == null) {
        return input;
    }
    else {
        return replace_1(input, oldValue, newValue);
    }
}

export function replaceWithRegex(expr, newValue, input) {
    if (input == null) {
        return input;
    }
    else {
        return replace_2(expr, input, newValue);
    }
}

export function contains(searchStr, inputStr) {
    const matchValue = [inputStr, searchStr];
    if (matchValue[0] === null) {
        return false;
    }
    else if (matchValue[1] === null) {
        return false;
    }
    else {
        return matchValue[0].indexOf(matchValue[1]) >= 0;
    }
}

export const removePunctuation = (str) => filter((c) => {
    if (isLetterOrDigit(c)) {
        return true;
    }
    else {
        return c === " ";
    }
}, str);

export function op_QmarkQmarkGreater(a, b) {
    if (isNullOrEmpty(b)) {
        return a;
    }
    else {
        return b;
    }
}

export function shorten(n, input) {
    if (isNotNull(input) && (input.length > n)) {
        return substring(input, 0, n - 2) + "..";
    }
    else {
        return input;
    }
}

export function splitByLengthReadable(n, s) {
    const splitByLengthRec = (str_mut, parts_mut) => {
        splitByLengthRec:
        while (true) {
            const str = str_mut, parts = parts_mut;
            const matchValue = str.length | 0;
            if (matchValue === 0) {
                return parts;
            }
            else if (matchValue <= n) {
                return append(parts, singleton(str));
            }
            else {
                const part = substring(str, 0, n);
                const lastSpace = part.lastIndexOf(" ") | 0;
                const patternInput = ((lastSpace > 1) && (lastSpace !== (n - 1))) ? [substring(str, 0, lastSpace), subStringFrom(lastSpace + 1, str)] : [part, subStringFrom(n - 1, str)];
                str_mut = patternInput[1];
                parts_mut = append(parts, singleton(patternInput[0]));
                continue splitByLengthRec;
            }
            break;
        }
    };
    return splitByLengthRec(s, empty());
}

