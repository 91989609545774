import { FSharpResult$2, Result_Bind } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Choice.js";
import { ErrorReason } from "../../Fame.Survey/Survey.UI/fable_modules/Thoth.Json.7.0.0/Types.fs.js";
import { string, uint32 } from "../../Fame.Survey/Survey.UI/fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { Uri } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Uri.js";
import { tryParse } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Decimal.js";
import Decimal from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Decimal.js";
import { FSharpRef } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Types.js";

export function uint8(path, token) {
    return Result_Bind((i) => {
        if (i > 255) {
            return new FSharpResult$2(1, [path, new ErrorReason(1, "an uint8", token, "Value was too large for an uint8")]);
        }
        else {
            return new FSharpResult$2(0, i & 0xFF);
        }
    }, uint32(path)(token));
}

export function uri(path, token) {
    return Result_Bind((s) => {
        try {
            return new FSharpResult$2(0, Uri.create(s, 0));
        }
        catch (matchValue) {
            return new FSharpResult$2(1, [path, new ErrorReason(0, "a uri", token)]);
        }
    }, string(path, token));
}

export function decimal(path, token) {
    return Result_Bind((s) => {
        let matchValue;
        let outArg = new Decimal(0);
        matchValue = [tryParse(s, new FSharpRef(() => outArg, (v) => {
            outArg = v;
        })), outArg];
        if (matchValue[0]) {
            return new FSharpResult$2(0, matchValue[1]);
        }
        else {
            return new FSharpResult$2(1, [path, new ErrorReason(0, "a decimal", token)]);
        }
    }, string(path, token));
}

