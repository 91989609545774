import { FSharpRef, toString, Record } from "../fable_modules/fable-library.3.7.12/Types.js";
import { Localization$reflection } from "../../../Fame/Localization/Localization.fs.js";
import { record_type, lambda_type, class_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { LongitudeModule_create, LatitudeModule_create, PossibleAnswerId, QuestionId$reflection } from "../../Survey/CommonTypes.fs.js";
import { SurveyMsg, UploadState, UploadState$reflection } from "./State.fs.js";
import { empty, singleton, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { contains, fold, ofArray, map as map_1, cons, singleton as singleton_1, empty as empty_1, isEmpty } from "../fable_modules/fable-library.3.7.12/List.js";
import { Text_p, Common_GenericOption, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { IInputType, Option } from "../fable_modules/Fulma.2.16.0/Elements/Form/Input.fs.js";
import { map as map_2, bind, defaultArg, value as value_4 } from "../fable_modules/fable-library.3.7.12/Option.js";
import { toOption } from "../../../Fame/Core/String.fs.js";
import { Browser_Types_Event__Event_get_Value } from "../fable_modules/Fable.React.7.4.3/Fable.React.Extensions.fs.js";
import { div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { input as input_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/./Input.fs.js";
import { Prop as Prop_1, DOMAttr, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { DualAnsweredQuestionTypeModule_state, AnswerState_value, AnswerValue, AnswerState_errors } from "../../Survey/AnswerQuestion.fs.js";
import { view } from "../Components/DateInput.fs.js";
import { op_Subtraction, op_Multiply, op_Addition, tryParse, fromParts, toString as toString_1 } from "../fable_modules/fable-library.3.7.12/Decimal.js";
import Decimal from "../fable_modules/fable-library.3.7.12/Decimal.js";
import { printf, toText } from "../fable_modules/fable-library.3.7.12/String.js";
import * as react from "react";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { Option as Option_1, select } from "../fable_modules/Fulma.2.16.0/Elements/Form/Select.fs.js";
import { checkbox, radio, Option as Option_2 } from "../fable_modules/Fulma.Extensions.Wikiki.Checkradio.2.0.1/Checkradio.fs.js";
import { find, empty as empty_2, add } from "../fable_modules/fable-library.3.7.12/Map.js";
import { uncurry, safeHash, equals } from "../fable_modules/fable-library.3.7.12/Util.js";
import { Option as Option_3, button as button_1 } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { Option as Option_4, help } from "../fable_modules/Fulma.2.16.0/Elements/Form/Help.fs.js";
import { Option as Option_5, image as image_1 } from "../fable_modules/Fulma.2.16.0/Elements/Image.fs.js";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { Prop, view as view_1 } from "../Components/GeoLocation.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";

export class QuestionViewServices extends Record {
    constructor(Localization, TimeZoneOffset, GetUploadState) {
        super();
        this.Localization = Localization;
        this.TimeZoneOffset = TimeZoneOffset;
        this.GetUploadState = GetUploadState;
    }
}

export function QuestionViewServices$reflection() {
    return record_type("Fame.Survey.UI.SurveyPage.QuestionTypeView.QuestionViewServices", [], QuestionViewServices, () => [["Localization", Localization$reflection()], ["TimeZoneOffset", class_type("System.TimeSpan")], ["GetUploadState", lambda_type(QuestionId$reflection(), UploadState$reflection())]]);
}

function getDefaultInputOptions(questionId, errors) {
    return toList(delay(() => append((!isEmpty(errors)) ? singleton(new Option(2, new Color_IColor(8))) : empty(), delay(() => singleton(new Option(3, toString(questionId)))))));
}

function getDefaultTextInputOptions(toAnswerValue, valueOption, errors, questionId, answerQuestion) {
    return toList(delay(() => append(getDefaultInputOptions(questionId, errors), delay(() => append((valueOption != null) ? singleton(new Option(10, value_4(valueOption))) : empty(), delay(() => singleton(new Option(13, (event) => {
        answerQuestion(toAnswerValue(toOption(Browser_Types_Event__Event_get_Value(event))));
    }))))))));
}

function textQuestionViewInternal(toAnswerValue, meta, valueOption, errors, questionId, answerQuestion) {
    return [div(empty_1(), singleton_1(input_1(cons(new Option(1, new IInputType(0)), toList(delay(() => append(getDefaultTextInputOptions(toAnswerValue, valueOption, errors, questionId, answerQuestion), delay(() => ((meta.AutoCompleteHint != null) ? singleton(new Option(15, singleton_1(new HTMLAttr(54, toString(value_4(meta.AutoCompleteHint)))))) : empty()))))))))), errors];
}

function textQuestionView(meta, state, questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    return textQuestionViewInternal((arg0) => (new AnswerValue(0, arg0)), meta, AnswerState_value(state), errors, questionId, answerQuestion);
}

function formattedTextQuestionView(meta, state, questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    return textQuestionViewInternal((arg0) => (new AnswerValue(1, arg0)), meta, AnswerState_value(state), errors, questionId, answerQuestion);
}

function dateQuestionView(meta, state, services, questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    const value = AnswerState_value(state);
    return [view(getDefaultInputOptions(questionId, errors), services.TimeZoneOffset, meta, value, (arg) => {
        answerQuestion(new AnswerValue(2, arg));
    }), errors];
}

function decimalQuestionView(meta, state, questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    return [input_1(cons(new Option(1, new IInputType(7)), toList(delay(() => append(getDefaultInputOptions(questionId, errors), delay(() => append(singleton(new Option(10, toString_1(defaultArg(AnswerState_value(state), fromParts(0, 0, 0, false, 0))))), delay(() => append((meta.Step != null) ? singleton(new Option(15, singleton_1(new HTMLAttr(154, value_4(meta.Step))))) : empty(), delay(() => append((meta.Min != null) ? singleton(new Option(15, singleton_1(new HTMLAttr(154, value_4(meta.Min))))) : empty(), delay(() => append((meta.Max != null) ? singleton(new Option(15, singleton_1(new HTMLAttr(154, value_4(meta.Max))))) : empty(), delay(() => singleton(new Option(13, (event) => {
        answerQuestion(new AnswerValue(3, bind((str) => {
            let patternInput;
            let outArg = new Decimal(0);
            patternInput = [tryParse(str, new FSharpRef(() => outArg, (v) => {
                outArg = v;
            })), outArg];
            if (patternInput[0]) {
                return patternInput[1];
            }
            else {
                return void 0;
            }
        }, toOption(Browser_Types_Event__Event_get_Value(event)))));
    })))))))))))))))), errors];
}

function getRating(ratingRange, value) {
    return op_Addition(op_Multiply(value, op_Subtraction(new Decimal(ratingRange.Max), new Decimal(ratingRange.Min))), new Decimal(ratingRange.Min));
}

function getPossibleAnswerText(answer, ratingRange, ratingValue) {
    if (ratingRange != null) {
        const arg10 = getRating(ratingRange, ratingValue);
        return toText(printf("(%.1f) %s"))(arg10)(answer.Text);
    }
    else {
        return answer.Text;
    }
}

function singleChoiceDropdown(possibleAnswers, state, ratingRange, answerQuestion) {
    let props_4;
    const errors = AnswerState_errors(state);
    const value = AnswerState_value(state);
    let options_1;
    const options = map_1((tupledArg) => {
        const answer = tupledArg[0];
        const props = [new HTMLAttr(161, toString(answer.Id))];
        const children = [getPossibleAnswerText(answer, ratingRange, tupledArg[1])];
        return react.createElement("option", keyValueList(props, 1), ...children);
    }, possibleAnswers);
    options_1 = cons(react.createElement("option", {}), options);
    return [select(toList(delay(() => ((!isEmpty(errors)) ? singleton(new Option_1(7, new Color_IColor(8))) : empty()))), singleton_1((props_4 = toList(delay(() => append((value != null) ? singleton(new HTMLAttr(1, value_4(value))) : empty(), delay(() => singleton(new DOMAttr(9, (ev) => {
        answerQuestion(map_2((arg0) => (new PossibleAnswerId(0, arg0)), toOption(Browser_Types_Event__Event_get_Value(ev))));
    })))))), react.createElement("select", keyValueList(props_4, 1), ...options_1)))), errors];
}

function checkradioList(possibleAnswers, questionId, createElement, getIsChecked, getText, answerQuestion) {
    const questionIdStr = toString(questionId);
    const children = map_1((answer) => {
        const answerIdStr = toString(answer.Id);
        const idStr = (questionIdStr + "_") + answerIdStr;
        const isChecked = getIsChecked(answer.Id);
        return createElement(ofArray([new Option_2(14, questionIdStr), new Option_2(13, idStr), new Option_2(6, isChecked), new Option_2(10, singleton_1(new HTMLAttr(161, answerIdStr))), new Option_2(11, (_ev) => {
            answerQuestion([answer.Id, !isChecked]);
        })]), singleton_1(getText(answer)));
    }, possibleAnswers);
    return react.createElement("div", {
        className: "checkradio-list",
    }, ...children);
}

function singleChoiceRadio(ratedPossibleAnswers, state, ratingRange, questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    const value = AnswerState_value(state);
    let getText;
    const textMap = fold((map, tupledArg) => {
        const answer = tupledArg[0];
        return add(answer.Id, getPossibleAnswerText(answer, ratingRange, tupledArg[1]), map);
    }, empty_2(), ratedPossibleAnswers);
    getText = ((answer_1) => find(answer_1.Id, textMap));
    return [checkradioList(map_1((tuple) => tuple[0], ratedPossibleAnswers), questionId, radio, (id) => {
        if (value == null) {
            return false;
        }
        else {
            return equals(value, id);
        }
    }, getText, (arg_1) => {
        answerQuestion(arg_1[0]);
    }), errors];
}

function singleChoiceQuestionView(typ, possibleAnswers, state, ratingRange, questionId, answerQuestion) {
    const answerSingleChoiceQuestion = (answerIdOpt) => {
        answerQuestion(new AnswerValue(4, answerIdOpt));
    };
    if (typ.tag === 1) {
        return singleChoiceRadio(possibleAnswers, state, ratingRange, questionId, answerSingleChoiceQuestion);
    }
    else {
        return singleChoiceDropdown(possibleAnswers, state, ratingRange, answerSingleChoiceQuestion);
    }
}

function multipleChoiceCheckbox(possibleAnswers, state, questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    const value = AnswerState_value(state);
    return [checkradioList(possibleAnswers, questionId, checkbox, (id) => {
        if (value == null) {
            return false;
        }
        else {
            return contains(id, value, {
                Equals: equals,
                GetHashCode: safeHash,
            });
        }
    }, (answer) => answer.Text, answerQuestion), errors];
}

function multipleChoiceQuestionView(typ, possibleAnswers, state, questionId, answerQuestion) {
    return multipleChoiceCheckbox(possibleAnswers, state, questionId, (tupledArg) => {
        answerQuestion(new AnswerValue(5, [tupledArg[0], tupledArg[1]]));
    });
}

function uploadQuestionView(res, typ, state, services, dispatch, questionId, _answerQuestion) {
    let children_2;
    const errors = AnswerState_errors(state);
    const value = AnswerState_value(state);
    const uploadState = services.GetUploadState(questionId);
    const questionIdStr = toString(questionId);
    let button;
    const patternInput = [res.UploadPicture, "image/*;capture=camera"];
    button = button_1(singleton_1(new Option_3(13, equals(uploadState, new UploadState(1)))), ofArray([patternInput[0], react.createElement("input", {
        type: "file",
        id: questionIdStr,
        name: questionIdStr,
        accept: patternInput[1],
        onChange: (ev) => {
            const element = ev.target;
            if (element.files.length === 0) {
            }
            else {
                const _arg1 = element.files[0];
                if (equals(_arg1, null)) {
                }
                else {
                    dispatch(new SurveyMsg(0, questionId, _arg1));
                }
            }
        },
    })]));
    const uploadErrorMessage = (uploadState.tag === 2) ? help(singleton_1(new Option_4(2, new Color_IColor(8))), singleton_1(uploadState.fields[0])) : (void 0);
    let preview;
    const matchValue = [typ, value];
    if (matchValue[1] != null) {
        const uri = matchValue[1];
        let uriStr;
        try {
            uriStr = ((uri.parseUrl()).toString());
        }
        catch (matchValue_1) {
            uriStr = "";
        }
        const image = image_1(singleton_1(new Option_5(14)), singleton_1(react.createElement("img", {
            src: uriStr,
        })));
        preview = box$0027(singleton_1(new Common_GenericOption(0, "preview")), singleton_1(react.createElement("a", {
            href: uriStr,
            target: "_blank",
        }, image)));
    }
    else {
        preview = (void 0);
    }
    return [(children_2 = toList(delay(() => append(singleton(button), delay(() => append((uploadErrorMessage != null) ? singleton(value_4(uploadErrorMessage)) : empty(), delay(() => ((preview != null) ? singleton(value_4(preview)) : empty()))))))), react.createElement("div", {
        className: "upload",
    }, ...children_2)), errors];
}

function geoLocationQuestionView(state, _questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    const value = AnswerState_value(state);
    let defaultCenter;
    const matchValue = [LatitudeModule_create("default latitude", 53), LongitudeModule_create("default longitude", 10)];
    let pattern_matching_result, lat, lon;
    const copyOfStruct = matchValue[0];
    if (copyOfStruct.tag === 0) {
        const copyOfStruct_1 = matchValue[1];
        if (copyOfStruct_1.tag === 0) {
            pattern_matching_result = 0;
            lat = copyOfStruct.fields[0];
            lon = copyOfStruct_1.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            defaultCenter = [lat, lon];
            break;
        }
        case 1: {
            defaultCenter = (void 0);
            break;
        }
    }
    return [view_1(toList(delay(() => {
        let tupledArg;
        return append((defaultCenter != null) ? singleton((tupledArg = value_4(defaultCenter), new Prop(0, tupledArg[0], tupledArg[1]))) : empty(), delay(() => append(singleton(new Prop(1, 8)), delay(() => singleton(new Prop(4, (arg) => {
            answerQuestion(new AnswerValue(7, arg));
        }))))));
    })), value), errors];
}

const emailQuestionViewInternal = FunctionComponent_Of_Z5A158BBF((tupledArg) => {
    const valueOption = tupledArg[1];
    const focusState = react.useState(false);
    return div(empty_1(), singleton_1(input_1(cons(new Option(1, new IInputType(8)), toList(delay(() => append(getDefaultTextInputOptions(tupledArg[3], valueOption, tupledArg[0], tupledArg[2], tupledArg[4]), delay(() => singleton(new Option(15, ofArray([new DOMAttr(8, (_arg1) => {
        focusState[1](false);
    }), new DOMAttr(7, (_arg2) => {
        focusState[1](true);
    }), new Prop_1(1, (el) => {
        if ((((!(el == null)) && (valueOption != null)) && (!(value_4(valueOption) == null))) && (!(focusState[0]))) {
            el.value = value_4(valueOption);
        }
    })])))))))))));
}, void 0, uncurry(2, void 0), void 0, "emailQuestionViewInternal", "/home/vsts/work/1/s/src/Fame.Survey/Survey.UI/SurveyPage/QuestionTypeView.fs", 312);

function emailQuestionView(state, questionId, answerQuestion) {
    const errors = AnswerState_errors(state);
    return [emailQuestionViewInternal([errors, AnswerState_value(state), questionId, (arg0) => (new AnswerValue(8, arg0)), answerQuestion]), errors];
}

function dualQuestionView(res, dualType, questionId, answerQuestion) {
    let children;
    const state = DualAnsweredQuestionTypeModule_state(dualType);
    const errors = AnswerState_errors(state);
    const value = AnswerState_value(state);
    const patternInput = (value == null) ? [void 0, void 0] : [value[0], value[1]];
    const secondValue = patternInput[1];
    const firstValue = patternInput[0];
    const viewFn = (dualType.tag === 1) ? ((value_1) => ((toAnswerValue) => textQuestionViewInternal(toAnswerValue, dualType.fields[0], value_1, errors, questionId, answerQuestion)[0])) : ((dualType.tag === 2) ? ((value_2) => ((toAnswerValue_1) => emailQuestionViewInternal([errors, value_2, questionId, toAnswerValue_1, answerQuestion]))) : ((value_1) => ((toAnswerValue) => textQuestionViewInternal(toAnswerValue, dualType.fields[0], value_1, errors, questionId, answerQuestion)[0])));
    return [(children = [viewFn(firstValue)((v) => (new AnswerValue(9, v, secondValue))), Text_p(empty_1(), singleton_1(res.PleaseRepeat)), viewFn(secondValue)((v_1) => (new AnswerValue(9, firstValue, v_1)))], react.createElement("div", {}, ...children)), errors];
}

export function questionTypeView(res, ratingRange, questionViewServices, questionType, questionId, answerQuestion, dispatch) {
    return ((questionType.tag === 1) ? ((questionId_2) => ((answerQuestion_2) => formattedTextQuestionView(questionType.fields[0], questionType.fields[2], questionId_2, answerQuestion_2))) : ((questionType.tag === 2) ? ((questionId_3) => ((answerQuestion_3) => dateQuestionView(questionType.fields[0], questionType.fields[1], questionViewServices, questionId_3, answerQuestion_3))) : ((questionType.tag === 3) ? ((questionId_4) => ((answerQuestion_4) => decimalQuestionView(questionType.fields[0], questionType.fields[1], questionId_4, answerQuestion_4))) : ((questionType.tag === 4) ? ((questionId_5) => ((answerQuestion_5) => singleChoiceQuestionView(questionType.fields[0], questionType.fields[1], questionType.fields[3], ratingRange, questionId_5, answerQuestion_5))) : ((questionType.tag === 5) ? ((questionId_6) => ((answerQuestion_6) => multipleChoiceQuestionView(questionType.fields[0], questionType.fields[1], questionType.fields[2], questionId_6, answerQuestion_6))) : ((questionType.tag === 6) ? ((questionId_7) => ((_answerQuestion) => uploadQuestionView(res, questionType.fields[0], questionType.fields[1], questionViewServices, dispatch, questionId_7, _answerQuestion))) : ((questionType.tag === 7) ? ((_questionId) => ((answerQuestion_7) => geoLocationQuestionView(questionType.fields[0], _questionId, answerQuestion_7))) : ((questionType.tag === 8) ? ((questionId_8) => ((answerQuestion_8) => emailQuestionView(questionType.fields[0], questionId_8, answerQuestion_8))) : ((questionType.tag === 9) ? ((questionId_9) => ((answerQuestion_9) => dualQuestionView(res, questionType.fields[0], questionId_9, answerQuestion_9))) : ((questionId_1) => ((answerQuestion_1) => textQuestionView(questionType.fields[0], questionType.fields[1], questionId_1, answerQuestion_1))))))))))))(questionId)(answerQuestion);
}

