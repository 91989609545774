import { AnswerValue, AnswerState_value, DecimalError, DateError, AnswerValidationInternalError, AnswerValidationError } from "./AnswerQuestion.fs.js";
import { tail, isEmpty, tryFind, ofArray, collect, map, empty, singleton } from "../Survey.UI/fable_modules/fable-library.3.7.12/List.js";
import { isNullOrWhiteSpace } from "../Survey.UI/fable_modules/fable-library.3.7.12/String.js";
import { concatNewLine } from "../../Fame/Core/String.fs.js";
import { getAllMessages } from "../../Fame/Core/Exception.fs.js";
import { create, isMatch } from "../Survey.UI/fable_modules/fable-library.3.7.12/RegExp.js";
import { compare } from "../Survey.UI/fable_modules/fable-library.3.7.12/Date.js";
import { op_Addition, equals, fromParts, op_Subtraction, op_Modulus, compare as compare_1 } from "../Survey.UI/fable_modules/fable-library.3.7.12/Decimal.js";
import { defaultArg } from "../Survey.UI/fable_modules/fable-library.3.7.12/Option.js";
import { equals as equals_1 } from "../Survey.UI/fable_modules/fable-library.3.7.12/Util.js";
import { EmailAddressModule_create } from "./CommonTypes.fs.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../Survey.UI/fable_modules/fable-library.3.7.12/Seq.js";

function validateRequired(_arg1) {
    let pattern_matching_result;
    if (_arg1.tag === 0) {
        if (_arg1.fields[0] != null) {
            if (isNullOrWhiteSpace(_arg1.fields[0])) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else if (_arg1.tag === 1) {
        if (_arg1.fields[0] != null) {
            if (isNullOrWhiteSpace(_arg1.fields[0])) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else if (_arg1.tag === 8) {
        if (_arg1.fields[0] != null) {
            if (isNullOrWhiteSpace(_arg1.fields[0])) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else if (_arg1.tag === 9) {
        if (_arg1.fields[0] != null) {
            if (isNullOrWhiteSpace(_arg1.fields[0])) {
                pattern_matching_result = 0;
            }
            else {
                pattern_matching_result = 1;
            }
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return singleton(new AnswerValidationError(5));
        }
        case 1: {
            let pattern_matching_result_1;
            if (_arg1.tag === 1) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 2) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 3) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 4) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 5) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 6) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 7) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 8) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.tag === 9) {
                if (_arg1.fields[0] == null) {
                    pattern_matching_result_1 = 1;
                }
                else {
                    pattern_matching_result_1 = 0;
                }
            }
            else if (_arg1.fields[0] == null) {
                pattern_matching_result_1 = 1;
            }
            else {
                pattern_matching_result_1 = 0;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return empty();
                }
                case 1: {
                    return singleton(new AnswerValidationError(5));
                }
            }
        }
    }
}

function validateText(_arg1) {
    if (_arg1.tag === 0) {
        return empty();
    }
    else {
        return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    }
}

function validateFormattedText(textFormat, answerValue) {
    if (answerValue.tag === 1) {
        if (answerValue.fields[0] != null) {
            const value = answerValue.fields[0];
            try {
                return isMatch(create(textFormat.Format), value) ? empty() : singleton(new AnswerValidationError(1, textFormat));
            }
            catch (e) {
                return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(1, concatNewLine(getAllMessages(e)))));
            }
        }
        else {
            return empty();
        }
    }
    else {
        return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    }
}

function validateDate(meta) {
    return (_arg1) => {
        let _strValue, dateValue;
        return (_arg1.tag === 2) ? ((_arg1.fields[0] != null) ? ((_arg1.fields[0].tag === 1) ? ((_strValue = _arg1.fields[0].fields[0], singleton(new AnswerValidationError(2, new DateError(2))))) : ((dateValue = _arg1.fields[0].fields[0], map((arg0) => (new AnswerValidationError(2, arg0)), collect((f) => f(dateValue), ofArray([(value) => {
            const matchValue = meta.Min;
            if (matchValue != null) {
                const min = matchValue;
                if (compare(min, value) <= 0) {
                    return empty();
                }
                else {
                    return singleton(new DateError(0, min));
                }
            }
            else {
                return empty();
            }
        }, (value_1) => {
            const matchValue_1 = meta.Max;
            if (matchValue_1 != null) {
                const max = matchValue_1;
                if (compare(value_1, max) <= 0) {
                    return empty();
                }
                else {
                    return singleton(new DateError(1, max));
                }
            }
            else {
                return empty();
            }
        }])))))) : empty()) : singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    };
}

function validateDecimal(meta) {
    return (_arg1) => {
        let value_3;
        return (_arg1.tag === 3) ? ((_arg1.fields[0] != null) ? ((value_3 = _arg1.fields[0], map((arg0) => (new AnswerValidationError(3, arg0)), collect((f) => f(value_3), ofArray([(value) => {
            const matchValue = meta.Min;
            if (matchValue != null) {
                const min = matchValue;
                if (compare_1(min, value) <= 0) {
                    return empty();
                }
                else {
                    return singleton(new DecimalError(0, min));
                }
            }
            else {
                return empty();
            }
        }, (value_1) => {
            const matchValue_1 = meta.Max;
            if (matchValue_1 != null) {
                const max = matchValue_1;
                if (compare_1(value_1, max) <= 0) {
                    return empty();
                }
                else {
                    return singleton(new DecimalError(1, max));
                }
            }
            else {
                return empty();
            }
        }, (value_2) => {
            let nu, matchValue_3, min_3, no, matchValue_4, max_2;
            const matchValue_2 = meta.Step;
            if (matchValue_2 != null) {
                const step = matchValue_2;
                const offset = op_Modulus(op_Subtraction(value_2, defaultArg(meta.Min, fromParts(0, 0, 0, false, 0))), step);
                if (equals(offset, fromParts(0, 0, 0, false, 0))) {
                    return empty();
                }
                else {
                    return singleton(new DecimalError(2, step, (nu = op_Subtraction(value_2, offset), (matchValue_3 = meta.Min, (matchValue_3 != null) ? ((compare_1(matchValue_3, nu) < 0) ? ((min_3 = matchValue_3, nu)) : ((matchValue_3 == null) ? nu : (void 0))) : ((matchValue_3 == null) ? nu : (void 0)))), (no = op_Addition(value_2, op_Subtraction(step, offset)), (matchValue_4 = meta.Max, (matchValue_4 != null) ? ((compare_1(no, matchValue_4) < 0) ? ((max_2 = matchValue_4, no)) : ((matchValue_4 == null) ? no : (void 0))) : ((matchValue_4 == null) ? no : (void 0))))));
                }
            }
            else {
                return empty();
            }
        }]))))) : empty()) : singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    };
}

function validatePossibleAnswer(possibleAnswers, answerId) {
    const _arg1 = tryFind((p) => equals_1(p.Id, answerId), possibleAnswers);
    if (_arg1 == null) {
        return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(2, answerId)));
    }
    else {
        return empty();
    }
}

function validateSingleChoice(ratedPossibleAnswers, _arg1) {
    if (_arg1.tag === 4) {
        if (_arg1.fields[0] != null) {
            const answerId = _arg1.fields[0];
            return validatePossibleAnswer(map((tuple) => tuple[0], ratedPossibleAnswers), answerId);
        }
        else {
            return empty();
        }
    }
    else {
        return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    }
}

function validateMultipleChoice(isRequired, possibleAnswers, oldAnswer, value) {
    const validateAnswerExists = (answerId) => validatePossibleAnswer(possibleAnswers, answerId);
    const matchValue = [isRequired, defaultArg(AnswerState_value(oldAnswer), empty()), value];
    let pattern_matching_result, answerId_1, answerId_2;
    if (matchValue[0]) {
        if (!isEmpty(matchValue[1])) {
            if (matchValue[2].tag === 5) {
                if (matchValue[2].fields[0] != null) {
                    if (matchValue[2].fields[0][1]) {
                        pattern_matching_result = 3;
                        answerId_2 = matchValue[2].fields[0][0];
                    }
                    else if (isEmpty(tail(matchValue[1]))) {
                        pattern_matching_result = 2;
                        answerId_1 = matchValue[2].fields[0][0];
                    }
                    else {
                        pattern_matching_result = 3;
                        answerId_2 = matchValue[2].fields[0][0];
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 4;
            }
        }
        else if (matchValue[2].tag === 5) {
            if (matchValue[2].fields[0] != null) {
                pattern_matching_result = 3;
                answerId_2 = matchValue[2].fields[0][0];
            }
            else {
                pattern_matching_result = 0;
            }
        }
        else {
            pattern_matching_result = 4;
        }
    }
    else if (matchValue[2].tag === 5) {
        if (matchValue[2].fields[0] != null) {
            pattern_matching_result = 3;
            answerId_2 = matchValue[2].fields[0][0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 4;
    }
    switch (pattern_matching_result) {
        case 0: {
            return singleton(new AnswerValidationError(5));
        }
        case 1: {
            return empty();
        }
        case 2: {
            const _arg1 = validateAnswerExists(answerId_1);
            if (isEmpty(_arg1)) {
                return singleton(new AnswerValidationError(5));
            }
            else {
                return _arg1;
            }
        }
        case 3: {
            return validateAnswerExists(answerId_2);
        }
        case 4: {
            return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
        }
    }
}

function validateUpload(_arg1) {
    if (_arg1.tag === 6) {
        return empty();
    }
    else {
        return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    }
}

function validateGeoLocation(_arg1) {
    if (_arg1.tag === 7) {
        return empty();
    }
    else {
        return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    }
}

function validateEmail(_arg1) {
    if (_arg1.tag === 8) {
        if (_arg1.fields[0] != null) {
            const email = _arg1.fields[0];
            const matchValue = EmailAddressModule_create("", email);
            if (matchValue.tag === 1) {
                return singleton(new AnswerValidationError(4));
            }
            else {
                return empty();
            }
        }
        else {
            return empty();
        }
    }
    else {
        return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
    }
}

function validateDual(dualType) {
    return (answerValue) => {
        if (answerValue.tag === 9) {
            const firstValue = answerValue.fields[0];
            return toList(delay(() => {
                let str;
                return append((str = firstValue, (dualType.tag === 1) ? validateFormattedText(dualType.fields[1], new AnswerValue(1, str)) : ((dualType.tag === 2) ? validateEmail(new AnswerValue(8, str)) : validateText(new AnswerValue(0, str)))), delay(() => ((!equals_1(firstValue, answerValue.fields[1])) ? singleton_1(new AnswerValidationError(6)) : empty_1())));
            }));
        }
        else {
            return singleton(new AnswerValidationError(0, new AnswerValidationInternalError(0)));
        }
    };
}

export function validateAnswer(question, answerValue) {
    let validateField;
    const matchValue = question.Type;
    switch (matchValue.tag) {
        case 1: {
            validateField = ((answerValue_1) => validateFormattedText(matchValue.fields[1], answerValue_1));
            break;
        }
        case 2: {
            validateField = validateDate(matchValue.fields[0]);
            break;
        }
        case 3: {
            validateField = validateDecimal(matchValue.fields[0]);
            break;
        }
        case 4: {
            validateField = ((_arg1_1) => validateSingleChoice(matchValue.fields[1], _arg1_1));
            break;
        }
        case 5: {
            validateField = ((value) => validateMultipleChoice(question.IsRequired, matchValue.fields[1], matchValue.fields[2], value));
            break;
        }
        case 6: {
            validateField = (validateUpload);
            break;
        }
        case 7: {
            validateField = (validateGeoLocation);
            break;
        }
        case 8: {
            validateField = (validateEmail);
            break;
        }
        case 9: {
            validateField = validateDual(matchValue.fields[0]);
            break;
        }
        default: {
            validateField = (validateText);
        }
    }
    return collect((f) => f(answerValue), toList(delay(() => append(question.IsRequired ? singleton_1(validateRequired) : empty_1(), delay(() => singleton_1(validateField))))));
}

