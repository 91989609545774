import { toString as toString_1, Union, Record } from "./fable_modules/fable-library.3.7.12/Types.js";
import { record_type, lambda_type, class_type, union_type, string_type, unit_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { FinishedSurvey$reflection, OpenSurvey$reflection, Survey$reflection } from "../Survey/AnswerQuestion.fs.js";
import { FSharpResult$2 } from "./fable_modules/fable-library.3.7.12/Choice.js";
import { Http_header, FormData_appendFile, Http_content, Http_request, Http_method, Http_send } from "./fable_modules/Fable.SimpleHttp.3.3.0/Http.fs.js";
import { Header, BodyContent, HttpMethod } from "./fable_modules/Fable.SimpleHttp.3.3.0/Types.fs.js";
import { ofList, empty } from "./fable_modules/fable-library.3.7.12/Map.js";
import { singleton } from "./fable_modules/fable-library.3.7.12/AsyncBuilder.js";
import { string, fromString } from "./fable_modules/Thoth.Json.7.0.0/Decode.fs.js";
import { uncurry } from "./fable_modules/fable-library.3.7.12/Util.js";
import { Auto_generateBoxedDecoderCached_79988AEF } from "./fable_modules/Thoth.Json.7.0.0/./Decode.fs.js";
import { extra as extra_1 } from "../../Fame/Json/Extra.fs.js";
import { printf, toText } from "./fable_modules/fable-library.3.7.12/String.js";
import { toString } from "./fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { Auto_generateBoxedEncoderCached_Z20B7B430 } from "./fable_modules/Thoth.Json.7.0.0/./Encode.fs.js";
import { singleton as singleton_1 } from "./fable_modules/fable-library.3.7.12/List.js";
import { bind, ignore } from "./fable_modules/FsToolkit.ErrorHandling.2.13.0/AsyncResult.fs.js";
import { AsyncResultCE_asyncResult, AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD } from "./fable_modules/FsToolkit.ErrorHandling.2.13.0/AsyncResultCE.fs.js";
import { some } from "./fable_modules/fable-library.3.7.12/Option.js";
import { Services as Services_1 } from "../Survey/Service.fs.js";

export class Services extends Record {
    constructor(LoadSurvey, AutoSaveSurvey, SaveSurvey, FinishSurvey, UploadFile) {
        super();
        this.LoadSurvey = LoadSurvey;
        this.AutoSaveSurvey = AutoSaveSurvey;
        this.SaveSurvey = SaveSurvey;
        this.FinishSurvey = FinishSurvey;
        this.UploadFile = UploadFile;
    }
}

export function Services$reflection() {
    return record_type("Fame.Survey.UI.Service.Services", [], Services, () => [["LoadSurvey", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [Survey$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Survey$reflection()]], [["ErrorValue", string_type]]])]))], ["AutoSaveSurvey", lambda_type(OpenSurvey$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]))], ["SaveSurvey", lambda_type(OpenSurvey$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]))], ["FinishSurvey", lambda_type(OpenSurvey$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [FinishedSurvey$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", FinishedSurvey$reflection()]], [["ErrorValue", string_type]]])]))], ["UploadFile", lambda_type(class_type("Browser.Types.File", void 0, File), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, string_type], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", string_type]]])]))]]);
}

export class Config extends Record {
    constructor(GetEndpointUri) {
        super();
        this.GetEndpointUri = GetEndpointUri;
    }
}

export function Config$reflection() {
    return record_type("Fame.Survey.UI.Service.Config", [], Config, () => [["GetEndpointUri", lambda_type(class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, string_type]), string_type)]]);
}

export class RemoteError extends Record {
    constructor(Message, ExceptionMessage, ExceptionType, StackTrace) {
        super();
        this.Message = Message;
        this.ExceptionMessage = ExceptionMessage;
        this.ExceptionType = ExceptionType;
        this.StackTrace = StackTrace;
    }
}

export function RemoteError$reflection() {
    return record_type("Fame.Survey.UI.Service.RemoteError", [], RemoteError, () => [["Message", string_type], ["ExceptionMessage", string_type], ["ExceptionType", string_type], ["StackTrace", string_type]]);
}

function loadSurvey(config, unitVar0) {
    const x = Http_send(Http_method(new HttpMethod(0), Http_request(config.GetEndpointUri(empty()))));
    return singleton.Bind(x, (arg) => {
        let value;
        const resp = arg;
        value = (((resp.statusCode >= 200) && (resp.statusCode <= 299)) ? fromString(uncurry(2, Auto_generateBoxedDecoderCached_79988AEF(Survey$reflection(), void 0, extra_1)), resp.responseText) : (new FSharpResult$2(1, toText(printf("loadsurvey failed: %d\n%s"))(resp.statusCode)(resp.responseText))));
        return singleton.Return(value);
    });
}

export class SaveType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Auto", "Manual", "Finish"];
    }
}

export function SaveType$reflection() {
    return union_type("Fame.Survey.UI.Service.SaveType", [], SaveType, () => [[], [], []]);
}

function saveSurveyWithType(saveType, config, survey) {
    let str;
    const x = Http_send(Http_content(new BodyContent(1, toString(0, Auto_generateBoxedEncoderCached_Z20B7B430(OpenSurvey$reflection(), void 0, extra_1)(survey))), Http_method(new HttpMethod(2), Http_request(config.GetEndpointUri(ofList(singleton_1(["save", (str = toString_1(saveType), str.toLowerCase())])))))));
    return singleton.Bind(x, (arg) => {
        let value_2;
        const response = arg;
        value_2 = (((response.statusCode >= 200) && (response.statusCode <= 299)) ? (new FSharpResult$2(0, response)) : (new FSharpResult$2(1, toText(printf("savesurvey failed: %d\n%s"))(response.statusCode)(response.responseText))));
        return singleton.Return(value_2);
    });
}

function autoSaveSurvey(config) {
    return (arg) => ignore(saveSurveyWithType(new SaveType(0), config, arg));
}

function manualSaveSurvey(config) {
    return (arg) => ignore(saveSurveyWithType(new SaveType(1), config, arg));
}

function finishSurvey(config) {
    return (arg) => bind((response) => AsyncResultCE_AsyncResultBuilder__Delay_Z64727ECD(AsyncResultCE_asyncResult, () => {
        const arg00 = fromString(uncurry(2, Auto_generateBoxedDecoderCached_79988AEF(FinishedSurvey$reflection(), void 0, extra_1)), response.responseText);
        return singleton.Return(arg00);
    }), saveSurveyWithType(new SaveType(2), config, arg));
}

function uploadFile(config, file) {
    const x = Http_send(Http_content(new BodyContent(3, FormData_appendFile("upload", file, new FormData())), Http_header(new Header(0, "Accept", "application/json"), Http_method(new HttpMethod(1), Http_request(config.GetEndpointUri(empty()))))));
    return singleton.Bind(x, (arg) => {
        let value_1;
        const response = arg;
        if ((response.statusCode >= 200) && (response.statusCode <= 299)) {
            value_1 = fromString(string, response.responseText);
        }
        else {
            const _arg1 = fromString(uncurry(2, Auto_generateBoxedDecoderCached_79988AEF(RemoteError$reflection(), void 0, void 0)), response.responseText);
            if (_arg1.tag === 1) {
                console.error(some("upload failed"), response.responseText);
                value_1 = (new FSharpResult$2(1, toText(printf("upload failed: %d\n%s"))(response.statusCode)(response.responseText)));
            }
            else {
                const remoteError = _arg1.fields[0];
                console.error(some("upload failed"), remoteError);
                value_1 = (new FSharpResult$2(1, toText(printf("upload failed: %s"))(remoteError.Message)));
            }
        }
        return singleton.Return(value_1);
    });
}

export function getServices(config) {
    return new Services(() => loadSurvey(config, void 0), autoSaveSurvey(config), manualSaveSurvey(config), finishSurvey(config), (file) => uploadFile(config, file));
}

export function toDomain(services) {
    return new Services_1(services.LoadSurvey, services.SaveSurvey, services.FinishSurvey);
}

