import { Record, Union } from "../Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { bool_type, uint8_type, float64_type, int32_type, option_type, class_type, record_type, list_type, union_type, string_type } from "../Survey.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { createMatch, createFloat } from "../../Fame/Types/Constraints.fs.js";

export class QuestionId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["QuestionId"];
    }
    toString() {
        const this$ = this;
        return this$.fields[0];
    }
}

export function QuestionId$reflection() {
    return union_type("Fame.Survey.CommonTypes.QuestionId", [], QuestionId, () => [[["Item", string_type]]]);
}

export class PossibleAnswerId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["PossibleAnswerId"];
    }
    toString() {
        const this$ = this;
        return this$.fields[0];
    }
}

export function PossibleAnswerId$reflection() {
    return union_type("Fame.Survey.CommonTypes.PossibleAnswerId", [], PossibleAnswerId, () => [[["Item", string_type]]]);
}

export class PossibleAnswer extends Record {
    constructor(Id, Text$, ShowQuestions) {
        super();
        this.Id = Id;
        this.Text = Text$;
        this.ShowQuestions = ShowQuestions;
    }
}

export function PossibleAnswer$reflection() {
    return record_type("Fame.Survey.CommonTypes.PossibleAnswer", [], PossibleAnswer, () => [["Id", PossibleAnswerId$reflection()], ["Text", string_type], ["ShowQuestions", list_type(QuestionId$reflection())]]);
}

export class AutoCompleteHint extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Name", "HonorificPrefix", "GivenName", "AdditionalName", "FamilyName", "HonorificSuffix", "Nickname", "Username", "NewPassword", "CurrentPassword", "OneTimeCode", "OrganizationTitle", "Organization", "StreetAddress", "AddressLine1", "AddressLine2", "AddressLine3", "AddressLevel4", "AddressLevel3", "AddressLevel2", "AddressLevel1", "Country", "CountryName", "PostalCode", "CcName", "CcGivenName", "CcAdditionalName", "CcFamilyName", "CcNumber", "CcExp", "CcExpMonth", "CcExpYear", "CcCsc", "CcType", "TransactionCurrency", "TransactionAmount", "Language", "Bday", "BdayDay", "BdayMonth", "BdayYear", "Sex", "Url", "Photo", "Tel", "TelCountryCode", "TelNational", "TelAreaCode", "TelLocal", "TelLocalPrefix", "TelLocalSuffix", "TelExtension", "Email", "Impp"];
    }
    toString() {
        const this$ = this;
        switch (this$.tag) {
            case 1: {
                return "honorific-prefix";
            }
            case 2: {
                return "given-name";
            }
            case 3: {
                return "additional-name";
            }
            case 4: {
                return "family-name";
            }
            case 5: {
                return "honorific-suffix";
            }
            case 6: {
                return "nickname";
            }
            case 7: {
                return "username";
            }
            case 8: {
                return "new-password";
            }
            case 9: {
                return "current-password";
            }
            case 10: {
                return "one-time-code";
            }
            case 11: {
                return "organization-title";
            }
            case 12: {
                return "organization";
            }
            case 13: {
                return "street-address";
            }
            case 14: {
                return "address-line1";
            }
            case 15: {
                return "address-line2";
            }
            case 16: {
                return "address-line3";
            }
            case 17: {
                return "address-level4";
            }
            case 18: {
                return "address-level3";
            }
            case 19: {
                return "address-level2";
            }
            case 20: {
                return "address-level1";
            }
            case 21: {
                return "country";
            }
            case 22: {
                return "country-name";
            }
            case 23: {
                return "postal-code";
            }
            case 24: {
                return "cc-name";
            }
            case 25: {
                return "cc-given-name";
            }
            case 26: {
                return "cc-additional-name";
            }
            case 27: {
                return "cc-family-name";
            }
            case 28: {
                return "cc-number";
            }
            case 29: {
                return "cc-exp";
            }
            case 30: {
                return "cc-exp-month";
            }
            case 31: {
                return "cc-exp-year";
            }
            case 32: {
                return "cc-csc";
            }
            case 33: {
                return "cc-type";
            }
            case 34: {
                return "transaction-currency";
            }
            case 35: {
                return "transaction-amount";
            }
            case 36: {
                return "language";
            }
            case 37: {
                return "bday";
            }
            case 38: {
                return "bday-day";
            }
            case 39: {
                return "bday-month";
            }
            case 40: {
                return "bday-year";
            }
            case 41: {
                return "sex";
            }
            case 42: {
                return "url";
            }
            case 43: {
                return "photo";
            }
            case 44: {
                return "tel";
            }
            case 45: {
                return "tel-country-code";
            }
            case 46: {
                return "tel-national";
            }
            case 47: {
                return "tel-area-code";
            }
            case 48: {
                return "tel-local";
            }
            case 49: {
                return "tel-local-prefix";
            }
            case 50: {
                return "tel-local-suffix";
            }
            case 51: {
                return "tel-extension";
            }
            case 52: {
                return "email";
            }
            case 53: {
                return "impp";
            }
            default: {
                return "name";
            }
        }
    }
}

export function AutoCompleteHint$reflection() {
    return union_type("Fame.Survey.CommonTypes.AutoCompleteHint", [], AutoCompleteHint, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export class DateMetaData extends Record {
    constructor(Min, Max, AutoCompleteHint) {
        super();
        this.Min = Min;
        this.Max = Max;
        this.AutoCompleteHint = AutoCompleteHint;
    }
}

export function DateMetaData$reflection() {
    return record_type("Fame.Survey.CommonTypes.DateMetaData", [], DateMetaData, () => [["Min", option_type(class_type("System.DateTimeOffset"))], ["Max", option_type(class_type("System.DateTimeOffset"))], ["AutoCompleteHint", option_type(AutoCompleteHint$reflection())]]);
}

export class DecimalMetaData extends Record {
    constructor(DecimalPlaces, Step, Min, Max) {
        super();
        this.DecimalPlaces = DecimalPlaces;
        this.Step = Step;
        this.Min = Min;
        this.Max = Max;
    }
}

export function DecimalMetaData$reflection() {
    return record_type("Fame.Survey.CommonTypes.DecimalMetaData", [], DecimalMetaData, () => [["DecimalPlaces", option_type(int32_type)], ["Step", option_type(class_type("System.Decimal"))], ["Min", option_type(class_type("System.Decimal"))], ["Max", option_type(class_type("System.Decimal"))]]);
}

export class SingleChoiceType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Dropdown", "Radio"];
    }
}

export function SingleChoiceType$reflection() {
    return union_type("Fame.Survey.CommonTypes.SingleChoiceType", [], SingleChoiceType, () => [[], []]);
}

export class MultipleChoiceType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Checkbox"];
    }
}

export function MultipleChoiceType$reflection() {
    return union_type("Fame.Survey.CommonTypes.MultipleChoiceType", [], MultipleChoiceType, () => [[]]);
}

export class UploadType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Image"];
    }
}

export function UploadType$reflection() {
    return union_type("Fame.Survey.CommonTypes.UploadType", [], UploadType, () => [[]]);
}

export class TextFormat extends Record {
    constructor(Format, InvalidFormatMessage) {
        super();
        this.Format = Format;
        this.InvalidFormatMessage = InvalidFormatMessage;
    }
}

export function TextFormat$reflection() {
    return record_type("Fame.Survey.CommonTypes.TextFormat", [], TextFormat, () => [["Format", string_type], ["InvalidFormatMessage", option_type(string_type)]]);
}

export class TextMetaData extends Record {
    constructor(AutoCompleteHint) {
        super();
        this.AutoCompleteHint = AutoCompleteHint;
    }
}

export function TextMetaData$reflection() {
    return record_type("Fame.Survey.CommonTypes.TextMetaData", [], TextMetaData, () => [["AutoCompleteHint", option_type(AutoCompleteHint$reflection())]]);
}

export class Latitude extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Latitude"];
    }
}

export function Latitude$reflection() {
    return union_type("Fame.Survey.CommonTypes.Latitude", [], Latitude, () => [[["Item", float64_type]]]);
}

export class Longitude extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Longitude"];
    }
}

export function Longitude$reflection() {
    return union_type("Fame.Survey.CommonTypes.Longitude", [], Longitude, () => [[["Item", float64_type]]]);
}

export class EmailAddress extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["EmailAddress"];
    }
}

export function EmailAddress$reflection() {
    return union_type("Fame.Survey.CommonTypes.EmailAddress", [], EmailAddress, () => [[["Item", string_type]]]);
}

export class Color extends Record {
    constructor(R, G, B) {
        super();
        this.R = R;
        this.G = G;
        this.B = B;
    }
}

export function Color$reflection() {
    return record_type("Fame.Survey.CommonTypes.Color", [], Color, () => [["R", uint8_type], ["G", uint8_type], ["B", uint8_type]]);
}

export class QuestionType extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Text", "FormattedText", "Date", "Time", "DateTime", "Decimal", "SingleChoice", "MultipleChoice", "Upload", "GeoLocation", "Email"];
    }
}

export function QuestionType$reflection() {
    return union_type("Fame.Survey.CommonTypes.QuestionType", [], QuestionType, () => [[["Item", TextMetaData$reflection()]], [["Item1", TextMetaData$reflection()], ["Item2", TextFormat$reflection()]], [], [], [], [["Item", DecimalMetaData$reflection()]], [["Item1", SingleChoiceType$reflection()], ["Item2", list_type(PossibleAnswer$reflection())]], [["Item1", MultipleChoiceType$reflection()], ["Item2", list_type(PossibleAnswer$reflection())]], [["Item", UploadType$reflection()]], [], []]);
}

export class Document$ extends Record {
    constructor(Name, Url) {
        super();
        this.Name = Name;
        this.Url = Url;
    }
}

export function Document$$reflection() {
    return record_type("Fame.Survey.CommonTypes.Document", [], Document$, () => [["Name", string_type], ["Url", string_type]]);
}

export class QuestionTemplate extends Record {
    constructor(Title, Text$, Type) {
        super();
        this.Title = Title;
        this.Text = Text$;
        this.Type = Type;
    }
}

export function QuestionTemplate$reflection() {
    return record_type("Fame.Survey.CommonTypes.QuestionTemplate", [], QuestionTemplate, () => [["Title", string_type], ["Text", option_type(string_type)], ["Type", QuestionType$reflection()]]);
}

export class Question extends Record {
    constructor(Id, VisibleIfAnswered, Group, Identifier, Title, Description, Warning, Document$, IsRequired, IsExclusionCriterion, RiskDescription, Type) {
        super();
        this.Id = Id;
        this.VisibleIfAnswered = VisibleIfAnswered;
        this.Group = Group;
        this.Identifier = Identifier;
        this.Title = Title;
        this.Description = Description;
        this.Warning = Warning;
        this.Document = Document$;
        this.IsRequired = IsRequired;
        this.IsExclusionCriterion = IsExclusionCriterion;
        this.RiskDescription = RiskDescription;
        this.Type = Type;
    }
}

export function Question$reflection() {
    return record_type("Fame.Survey.CommonTypes.Question", [], Question, () => [["Id", QuestionId$reflection()], ["VisibleIfAnswered", list_type(PossibleAnswerId$reflection())], ["Group", option_type(string_type)], ["Identifier", string_type], ["Title", string_type], ["Description", option_type(string_type)], ["Warning", option_type(string_type)], ["Document", option_type(Document$$reflection())], ["IsRequired", bool_type], ["IsExclusionCriterion", bool_type], ["RiskDescription", option_type(string_type)], ["Type", QuestionType$reflection()]]);
}

export class SurveyTypeId extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SurveyTypeId"];
    }
    toString() {
        const this$ = this;
        return this$.fields[0];
    }
}

export function SurveyTypeId$reflection() {
    return union_type("Fame.Survey.CommonTypes.SurveyTypeId", [], SurveyTypeId, () => [[["Item", string_type]]]);
}

export class SurveyType extends Record {
    constructor(Id, Title, Text$, Color, Questions) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.Text = Text$;
        this.Color = Color;
        this.Questions = Questions;
    }
}

export function SurveyType$reflection() {
    return record_type("Fame.Survey.CommonTypes.SurveyType", [], SurveyType, () => [["Id", SurveyTypeId$reflection()], ["Title", string_type], ["Text", option_type(string_type)], ["Color", option_type(Color$reflection())], ["Questions", list_type(Question$reflection())]]);
}

export function LatitudeModule_value(_arg1) {
    return _arg1.fields[0];
}

export function LatitudeModule_create(fieldName, lat) {
    return createFloat(fieldName, (arg0) => (new Latitude(0, arg0)), -90, 90, lat);
}

export function LongitudeModule_value(_arg1) {
    return _arg1.fields[0];
}

export function LongitudeModule_create(fieldName, lon) {
    return createFloat(fieldName, (arg0) => (new Longitude(0, arg0)), -180, 180, lon);
}

export const EmailAddressModule_RE = /^(((("[\x20\x21\x23-\x5B\x5D-\x7E]+")|([A-Za-z0-9\x21\x23-\x27\x2A\x2B\x2D\x2F\x3D\x3F\x5E-\x60\x7B-\x7E]+))\.)*(("[\x20\x21\x23-\x5B\x5D-\x7E]+")|([A-Za-z0-9\x21\x23-\x27\x2A\x2B\x2D\x2F\x3D\x3F\x5E-\x60\x7B-\x7E]+))@(([0-9a-z]|([0-9a-z][0-9a-z-]*[0-9a-z]))\.)+[a-z]{2,63})$/g;

export function EmailAddressModule_value(_arg1) {
    return _arg1.fields[0];
}

export function EmailAddressModule_create(fieldName, email) {
    return createMatch(fieldName, (arg0) => (new EmailAddress(0, arg0)), EmailAddressModule_RE, email);
}

