import { Record, Union } from "../fable_modules/fable-library.3.7.12/Types.js";
import { record_type, bool_type, lambda_type, unit_type, option_type, tuple_type, float64_type, union_type, string_type } from "../fable_modules/fable-library.3.7.12/Reflection.js";
import { DivIcon as DivIcon_1 } from "leaflet";
import { LongitudeModule_create, LatitudeModule_create, LatitudeModule_value, LongitudeModule_value, Longitude$reflection, Latitude$reflection } from "../../Survey/CommonTypes.fs.js";
import { FunctionComponent_Of_Z5A158BBF } from "../fable_modules/Fable.React.7.4.3/Fable.React.FunctionComponent.fs.js";
import * as react from "react";
import { empty, singleton, filter, map, ofArray, append } from "../fable_modules/fable-library.3.7.12/List.js";
import { MarkerProps, MapProps } from "../fable_modules/Fable.ReactLeaflet.1.0.1/ReactLeaflet.fs.js";
import { value as value_2, map as map_1 } from "../fable_modules/fable-library.3.7.12/Option.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { Map as Map$, TileLayer, Marker } from "react-leaflet";
import { box$0027 } from "../fable_modules/Fulma.2.16.0/Elements/Box.fs.js";
import { empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { uncurry } from "../fable_modules/fable-library.3.7.12/Util.js";

class DivIconProp extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["ClassName"];
    }
}

function DivIconProp$reflection() {
    return union_type("Fame.Survey.UI.Components.GeoLocationSelect.DivIconProp", [], DivIconProp, () => [[["Item", string_type]]]);
}

const DivIcon = DivIcon_1;

export class Prop extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["DefaultCenter", "Zoom", "MinZoom", "MaxZoom", "OnChange"];
    }
}

export function Prop$reflection() {
    return union_type("Fame.Survey.UI.Components.GeoLocationSelect.Prop", [], Prop, () => [[["Item1", Latitude$reflection()], ["Item2", Longitude$reflection()]], [["Item", float64_type]], [["Item", float64_type]], [["Item", float64_type]], [["Item", lambda_type(option_type(tuple_type(Latitude$reflection(), Longitude$reflection())), unit_type)]]]);
}

function toLeafletLatLng(latitude, longitude) {
    const lonVal = LongitudeModule_value(longitude);
    return [LatitudeModule_value(latitude), lonVal];
}

function tryOfLeafletLatLng(latVal, lonVal) {
    const matchValue = [LatitudeModule_create("latitude", latVal), LongitudeModule_create("longitude", lonVal)];
    let pattern_matching_result, lat, lon;
    const copyOfStruct = matchValue[0];
    if (copyOfStruct.tag === 0) {
        const copyOfStruct_1 = matchValue[1];
        if (copyOfStruct_1.tag === 0) {
            pattern_matching_result = 0;
            lat = copyOfStruct.fields[0];
            lon = copyOfStruct_1.fields[0];
        }
        else {
            pattern_matching_result = 1;
        }
    }
    else {
        pattern_matching_result = 1;
    }
    switch (pattern_matching_result) {
        case 0: {
            return [lat, lon];
        }
        case 1: {
            return void 0;
        }
    }
}

function onClick(onChange, ev) {
    return onChange(tryOfLeafletLatLng(ev.latlng.lat, ev.latlng.lng));
}

class UIState extends Record {
    constructor(HasLocated, Location) {
        super();
        this.HasLocated = HasLocated;
        this.Location = Location;
    }
}

function UIState$reflection() {
    return record_type("Fame.Survey.UI.Components.GeoLocationSelect.UIState", [], UIState, () => [["HasLocated", bool_type], ["Location", option_type(tuple_type(Latitude$reflection(), Longitude$reflection()))]]);
}

const defaultUIState = new UIState(false, void 0);

const viewInternal = FunctionComponent_Of_Z5A158BBF((tupledArg) => {
    let children_4, props_5;
    const state = react.useState(defaultUIState);
    const mapOptions = append(ofArray([new MapProps(37, "geolocation-select"), new MapProps(84, (ev_1) => {
        const latlon = tryOfLeafletLatLng(ev_1.latlng.lat, ev_1.latlng.lng);
        state[1](new UIState((state[0]).HasLocated, latlon));
    }), new MapProps(88, (mapEl) => {
        if (!(mapEl == null)) {
            mapEl.leafletElement.invalidateSize();
            if (!(state[0]).HasLocated) {
                mapEl.leafletElement.locate();
                state[1](new UIState(true, (state[0]).Location));
            }
        }
    })]), map((_arg1) => {
        switch (_arg1.tag) {
            case 1: {
                return new MapProps(42, _arg1.fields[0]);
            }
            case 2: {
                return new MapProps(40, _arg1.fields[0]);
            }
            case 3: {
                return new MapProps(45, _arg1.fields[0]);
            }
            case 4: {
                return new MapProps(54, (ev) => {
                    onClick(_arg1.fields[0], ev);
                });
            }
            default: {
                return new MapProps(36, toLeafletLatLng(_arg1.fields[0], _arg1.fields[1]));
            }
        }
    }, tupledArg[0]));
    let mapOptions_1;
    const matchValue = (state[0]).Location;
    if (matchValue != null) {
        const list2_1 = filter((_arg2) => {
            if (_arg2.tag === 36) {
                return false;
            }
            else {
                return true;
            }
        }, mapOptions);
        mapOptions_1 = append(singleton(new MapProps(36, toLeafletLatLng(matchValue[0], matchValue[1]))), list2_1);
    }
    else {
        mapOptions_1 = mapOptions;
    }
    const selectedMarker = map_1((tupledArg_1) => {
        let divIcon;
        const arg00 = {
            className: "geolocation-marker",
        };
        divIcon = (new DivIcon(arg00));
        const props = ofArray([new MarkerProps(13, toLeafletLatLng(tupledArg_1[0], tupledArg_1[1])), new MarkerProps(3, divIcon)]);
        const props_1 = keyValueList(props, 1);
        return react.createElement(Marker, props_1);
    }, tupledArg[1]);
    return box$0027(empty(), singleton((children_4 = toList(delay(() => {
        let props_3;
        return append_1(singleton_1((props_3 = {
            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            attribution: "\u0026copy; \u003ca href=\"http://osm.org/copyright\"\u003eOpenStreetMap\u003c/a\u003e contributors",
        }, react.createElement(TileLayer, props_3))), delay(() => ((selectedMarker != null) ? singleton_1(value_2(selectedMarker)) : empty_1())));
    })), (props_5 = keyValueList(mapOptions_1, 1), react.createElement(Map$, props_5, ...children_4)))));
}, void 0, uncurry(2, void 0), void 0, "viewInternal", "/home/vsts/work/1/s/src/Fame.Survey/Survey.UI/Components/GeoLocation.fs", 55);

export function view(options, selected) {
    return viewInternal([options, selected]);
}

