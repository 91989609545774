import { toString } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Decimal.js";

export function uint8(value) {
    return value;
}

export function uri(value) {
    return (value.parseUrl()).toString();
}

export function decimal(value) {
    return toString(value);
}

