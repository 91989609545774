import { replace, toOption } from "../../../Fame/Core/String.fs.js";
import { Option, label as label_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Label.fs.js";
import { toString } from "../fable_modules/fable-library.3.7.12/Types.js";
import { sumBy, tryPick, choose, collect, isEmpty, forAll, tryItem, tryFindIndex, cons, empty as empty_1, ofArray, map, append as append_1, contains, singleton } from "../fable_modules/fable-library.3.7.12/List.js";
import { map as map_2, empty, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.3.7.12/Seq.js";
import { Option as Option_1, tag as tag_8 } from "../fable_modules/Fulma.2.16.0/Elements/Tag.fs.js";
import * as react from "react";
import { QuestionViewServices, questionTypeView } from "./QuestionTypeView.fs.js";
import { safeHash, equals, partialApply } from "../fable_modules/fable-library.3.7.12/Util.js";
import { OpenSurvey_get_SectionsL, AnsweredQuestionTypeModule_errors, AnsweredQuestionTypeModule_hasValidValue, AnsweredQuestion_get_TypeL, AnswerValidationErrorModule_toHumanReadable, AnswerValidationError } from "../../Survey/AnswerQuestion.fs.js";
import { Option as Option_2, help } from "../fable_modules/Fulma.2.16.0/Elements/Form/Help.fs.js";
import { Size_ISize, Common_GenericOption, Modifier_IModifier, TextWeight_Option, Text_span, Color_IColor } from "../fable_modules/Fulma.2.16.0/Common.fs.js";
import { map as map_1 } from "../fable_modules/fable-library.3.7.12/Array.js";
import { printf, toText, split, substring } from "../fable_modules/fable-library.3.7.12/String.js";
import { body, Option as Option_3, message } from "../fable_modules/Fulma.2.16.0/Components/Message.fs.js";
import { Option as Option_4, button } from "../fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { icon as icon_1 } from "../fable_modules/Fulma.2.16.0/Elements/Icon.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.2.0.0/FontAwesome.fs.js";
import { UploadState, SaveState, SurveyMsg } from "./State.fs.js";
import { defaultArg, value as value_5 } from "../fable_modules/fable-library.3.7.12/Option.js";
import { CSSProp, HTMLAttr } from "../fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { keyValueList } from "../fable_modules/fable-library.3.7.12/MapUtil.js";
import { Option as Option_5, div } from "../fable_modules/Fulma.2.16.0/Elements/Form/Field.fs.js";
import { div as div_1 } from "../fable_modules/Fulma.2.16.0/Elements/Form/Control.fs.js";
import { tryFind } from "../fable_modules/fable-library.3.7.12/Map.js";
import { p, h1, Option as Option_6, h2 } from "../fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { op_Division, equals as equals_1, fromParts, op_Subtraction, op_Multiply, op_Addition } from "../fable_modules/fable-library.3.7.12/Decimal.js";
import Decimal from "../fable_modules/fable-library.3.7.12/Decimal.js";
import { Animate_Prop, Animate_animate } from "../../../Import/Fable/Fable.ReactSimpleAnimate.fs.js";
import { fromSeconds } from "../fable_modules/fable-library.3.7.12/TimeSpan.js";
import { SaveType } from "../Service.fs.js";
import { Optic_Get, Optic_Get_op_HatDot_21762A61 } from "../../../Fame/Optics/Aether.fs.js";
import { Option as Option_7, section as section_2 } from "../fable_modules/Fulma.2.16.0/Layouts/Section.fs.js";
import { container } from "../fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { body as body_1, Option as Option_8, hero } from "../fable_modules/Fulma.2.16.0/Layouts/Hero.fs.js";
import { SurveyOperation, SurveyCommand } from "../../Survey/Api.fs.js";

export function questionView(res, shouldDisplayRequiredWarnings, ratingRange, questionViewServices, suggestions, question, answerQuestion, dispatch) {
    let list, matchValue_1, f1;
    let label;
    const matchValue = toOption(question.Title);
    if (matchValue != null) {
        const title = matchValue;
        label = label_1(singleton(new Option(1, toString(question.QuestionId))), toList(delay(() => append(singleton_1(tag_8(singleton(new Option_1(5, "question-identifier")), singleton(question.Identifier))), delay(() => append(singleton_1(title), delay(() => (question.IsRequired ? singleton_1(react.createElement("span", {
            className: "required-star",
        }, "*")) : empty()))))))));
    }
    else {
        label = "";
    }
    let fieldBodyContent;
    let patternInput_1;
    const patternInput = questionTypeView(res, ratingRange, questionViewServices, question.Type, question.QuestionId, partialApply(1, answerQuestion, [question.QuestionId]), dispatch);
    const errors = patternInput[1];
    patternInput_1 = [patternInput[0], (list = ((matchValue_1 = [shouldDisplayRequiredWarnings, contains(new AnswerValidationError(5), errors, {
        Equals: equals,
        GetHashCode: safeHash,
    })], matchValue_1[0] ? (matchValue_1[1] ? errors : append_1(errors, singleton(new AnswerValidationError(5)))) : errors)), map((f1 = AnswerValidationErrorModule_toHumanReadable(res.Errors), (arg) => help(singleton(new Option_2(2, new Color_IColor(8))), singleton(f1(arg)))), list))];
    const suggestionEls = map_1((suggestion) => {
        let btn, children_4;
        let inlineEl;
        let pattern_matching_result, newValue, suggested;
        if (suggestion.tag === 1) {
            pattern_matching_result = 0;
            newValue = suggestion.fields[0].fields[2];
            suggested = suggestion.fields[0].fields[1];
        }
        else {
            pattern_matching_result = 0;
            newValue = suggestion.fields[0].fields[2];
            suggested = suggestion.fields[0].fields[1];
        }
        switch (pattern_matching_result) {
            case 0: {
                const index = newValue.indexOf(suggested) | 0;
                const start = substring(newValue, 0, index);
                const ending = substring(newValue, index + suggested.length);
                const children_2 = [start, Text_span(singleton(new Common_GenericOption(2, singleton(new Modifier_IModifier(2, new TextWeight_Option(3))))), singleton(suggested)), ending];
                inlineEl = react.createElement("span", {}, ...children_2);
                break;
            }
        }
        return message(ofArray([new Option_3(0, new Color_IColor(5)), new Option_3(3, "suggestion")]), singleton(body(empty_1(), ofArray([(btn = ((color, icon, msg) => button(ofArray([new Option_4(1, new Size_ISize(0)), new Option_4(0, color), new Option_4(18, (_arg1) => {
            dispatch(msg);
        })]), singleton(icon_1(empty_1(), singleton(Fa_i(singleton(icon), [])))))), (children_4 = [btn(new Color_IColor(8), new Fa_IconOption(11, "fas fa-times"), new SurveyMsg(10, question.QuestionId, suggestion)), " ", btn(new Color_IColor(6), new Fa_IconOption(11, "fas fa-check"), new SurveyMsg(9, question.QuestionId, suggestion))], react.createElement("div", {
            className: "is-pulled-right",
        }, ...children_4))), res.DidYouMean, " ", inlineEl, "?"]))));
    }, suggestions);
    fieldBodyContent = toList(delay(() => append(singleton_1(patternInput_1[0]), delay(() => append(patternInput_1[1], delay(() => append(suggestionEls, delay(() => append((question.Description != null) ? map_2((line) => react.createElement("p", {}, line), split(value_5(question.Description), ["\n", "\r"], null, 1)) : empty(), delay(() => {
        let props_8, children_8;
        return append((question.Warning != null) ? ((value_5(question.Warning).indexOf("http") === 0) ? singleton_1(message(singleton(new Option_3(0, new Color_IColor(7))), singleton(body(empty_1(), singleton((props_8 = [new HTMLAttr(94, value_5(question.Warning))], (children_8 = [value_5(question.Warning)], react.createElement("a", keyValueList(props_8, 1), ...children_8)))))))) : singleton_1(message(singleton(new Option_3(0, new Color_IColor(7))), singleton(body(empty_1(), toList(delay(() => map_2((line_1) => react.createElement("p", {}, line_1), split(value_5(question.Warning), ["\n", "\r"], null, 1))))))))) : empty(), delay(() => {
            let children_14, props_12, children_12;
            return (question.Document != null) ? singleton_1((children_14 = [(props_12 = [new HTMLAttr(94, value_5(question.Document).Url), new HTMLAttr(157, "_blank")], (children_12 = [value_5(question.Document).Name], react.createElement("a", keyValueList(props_12, 1), ...children_12)))], react.createElement("p", {}, ...children_14))) : empty();
        }));
    }))))))))));
    return div(toList(delay(() => (question.HasDangerHighlight ? singleton_1(new Option_5(10, "is-danger")) : empty()))), ofArray([label, div_1(empty_1(), fieldBodyContent)]));
}

export function sectionView(res, isVisible, shouldDisplayRequiredWarnings, ratingRange, questionViewServices, answerSuggestions, section, answerQuestion, dispatch) {
    const questions = map((question) => {
        let value;
        return questionView(res, shouldDisplayRequiredWarnings, ratingRange, questionViewServices, (value = (new Array(0)), defaultArg(tryFind(question.QuestionId, answerSuggestions), value)), question, answerQuestion, dispatch);
    }, section.Questions);
    const cssClass = (isVisible ? "is-selected " : "") + "survey-section";
    const children = cons(h2(singleton(new Option_6(9, singleton(new HTMLAttr(99, toString(section.Id))))))(singleton(section.Title)), questions);
    return react.createElement("div", {
        className: cssClass,
    }, ...children);
}

export function getRating(ratingRange, value) {
    return op_Addition(op_Multiply(value, op_Subtraction(new Decimal(ratingRange.Max), new Decimal(ratingRange.Min))), new Decimal(ratingRange.Min));
}

export function actions(res, model, dispatch) {
    let matchValue_2, divisor, children_9, children_13;
    const survey = model.Survey;
    const icon = (typ) => icon_1(empty_1(), singleton(Fa_i(singleton(typ), [])));
    let patternInput_1;
    let patternInput;
    const selectedIndex = defaultArg(tryFindIndex((s) => equals(s.Id, model.SelectedSectionId), survey.Sections), 0) | 0;
    patternInput = [tryItem(selectedIndex - 1, survey.Sections), tryItem(selectedIndex + 1, survey.Sections)];
    const prevSection = patternInput[0];
    const nextSection = patternInput[1];
    const navButton = (isDisabled, msg, children) => button(ofArray([new Option_4(19, "survey-nav"), new Option_4(16, isDisabled), new Option_4(18, (_arg1) => {
        dispatch(msg);
    })]), children);
    const navTitle = (section_1, defaultTitle) => {
        let section;
        const children_1 = [(section = section_1, (section == null) ? defaultTitle : section.Title)];
        return react.createElement("span", {
            className: "survey-nav-title",
        }, ...children_1);
    };
    patternInput_1 = [navButton(prevSection == null, new SurveyMsg(5), ofArray([icon(new Fa_IconOption(11, "fas fa-chevron-left")), navTitle(prevSection, res.Previous)])), navButton(nextSection == null, new SurveyMsg(6), ofArray([navTitle(nextSection, res.Next), icon(new Fa_IconOption(11, "fas fa-chevron-right"))]))];
    const isSaving = model.SaveState.tag === 1;
    const buttonSavedAnimation = (saveType, text) => {
        const getIcon = (r) => {
            if (r.tag === 1) {
                return new Fa_IconOption(11, "fas fa-frown");
            }
            else {
                return new Fa_IconOption(11, "fas fa-check");
            }
        };
        const getAnimation = (iconOption) => {
            let children_3;
            return ofArray([Animate_animate([new Animate_Prop(0, true), new Animate_Prop(4, fromSeconds(0.5)), new Animate_Prop(2, [new CSSProp(261, 1), new CSSProp(367, "translateY(0)")]), new Animate_Prop(1, [new CSSProp(261, 0), new CSSProp(367, "translateY(-40px)")]), new Animate_Prop(3, [new CSSProp(125, "none")])], [(children_3 = [icon(iconOption)], react.createElement("span", {}, ...children_3))]), Animate_animate([new Animate_Prop(0, true), new Animate_Prop(4, fromSeconds(0.5)), new Animate_Prop(2, [new CSSProp(261, 0)]), new Animate_Prop(1, [new CSSProp(261, 1)])], [react.createElement("span", {}, text)])]);
        };
        const matchValue_1 = [saveType, model.SaveState];
        let pattern_matching_result, result, result_1;
        if (matchValue_1[0].tag === 0) {
            if (matchValue_1[1].tag === 2) {
                if (matchValue_1[1].fields[0].tag === 1) {
                    pattern_matching_result = 0;
                    result = matchValue_1[1].fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else if (matchValue_1[0].tag === 2) {
            if (matchValue_1[1].tag === 2) {
                if (matchValue_1[1].fields[0].tag === 2) {
                    pattern_matching_result = 1;
                    result_1 = matchValue_1[1].fields[0].fields[0];
                }
                else {
                    pattern_matching_result = 2;
                }
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else if (matchValue_1[1].tag === 2) {
            if (matchValue_1[1].fields[0].tag === 0) {
                pattern_matching_result = 0;
                result = matchValue_1[1].fields[0].fields[0];
            }
            else {
                pattern_matching_result = 2;
            }
        }
        else {
            pattern_matching_result = 2;
        }
        switch (pattern_matching_result) {
            case 0: {
                return getAnimation(getIcon(result));
            }
            case 1: {
                return getAnimation(getIcon(result_1));
            }
            case 2: {
                return singleton(react.createElement("span", {}, text));
            }
        }
    };
    let saveButton;
    const title = defaultArg(survey.SaveButtonTitle, res.Save);
    saveButton = button(ofArray([new Option_4(18, (_arg2) => {
        dispatch(new SurveyMsg(12, new SaveType(1)));
    }), new Option_4(16, isSaving), new Option_4(13, (matchValue_2 = model.SaveState, (matchValue_2.tag === 1) && ((matchValue_2.fields[0].tag === 1) ? true : (matchValue_2.fields[0].tag === 0))))]), buttonSavedAnimation(new SaveType(1), title));
    let finalizeButton;
    const title_1 = defaultArg(survey.FinishButtonTitle, res.Finish);
    finalizeButton = button(ofArray([new Option_4(0, new Color_IColor(6)), new Option_4(18, (_arg3) => {
        dispatch(new SurveyMsg(12, new SaveType(2)));
    }), new Option_4(16, (!forAll((q) => {
        const typ_1 = Optic_Get_op_HatDot_21762A61(new Optic_Get(0), AnsweredQuestion_get_TypeL())(q);
        if (q.IsRequired) {
            return AnsweredQuestionTypeModule_hasValidValue(typ_1);
        }
        else {
            return isEmpty(AnsweredQuestionTypeModule_errors(typ_1));
        }
    }, collect((s_6) => s_6.Questions, Optic_Get_op_HatDot_21762A61(new Optic_Get(0), OpenSurvey_get_SectionsL())(survey)))) ? true : isSaving), new Option_4(13, equals(model.SaveState, new SaveState(1, new SaveType(2))))]), buttonSavedAnimation(new SaveType(2), title_1));
    let ratingText;
    const matchValue_3 = survey.RatingRange;
    if (matchValue_3 != null) {
        const ratingRange = matchValue_3;
        const ratingValues = choose((q_1) => {
            const ifRequired = (weighting) => {
                if (q_1.HasDangerHighlight ? true : q_1.IsRequired) {
                    return weighting | 0;
                }
                else {
                    return 0;
                }
            };
            const matchValue_4 = q_1.Type;
            let pattern_matching_result_1, possibleAnswerId, ratedPossibleAnswers, weighting_1, weighting_2;
            if (matchValue_4.tag === 4) {
                if (matchValue_4.fields[3].tag === 0) {
                    if (matchValue_4.fields[3].fields[0] != null) {
                        pattern_matching_result_1 = 0;
                        possibleAnswerId = matchValue_4.fields[3].fields[0];
                        ratedPossibleAnswers = matchValue_4.fields[1];
                        weighting_1 = matchValue_4.fields[2].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                        weighting_2 = matchValue_4.fields[2].fields[0];
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                    weighting_2 = matchValue_4.fields[2].fields[0];
                }
            }
            else {
                pattern_matching_result_1 = 2;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    const singleChoiseAnswer = tryPick((tupledArg) => {
                        if (equals(tupledArg[0].Id, possibleAnswerId)) {
                            return [tupledArg[1], weighting_1];
                        }
                        else {
                            return void 0;
                        }
                    }, ratedPossibleAnswers);
                    if (singleChoiseAnswer == null) {
                        return [fromParts(0, 0, 0, false, 0), ifRequired(weighting_1)];
                    }
                    else {
                        return singleChoiseAnswer;
                    }
                }
                case 1: {
                    return [fromParts(0, 0, 0, false, 0), ifRequired(weighting_2)];
                }
                case 2: {
                    return void 0;
                }
            }
        }, collect((s_7) => s_7.Questions, survey.Sections));
        const fullRating = getRating(ratingRange, isEmpty(ratingValues) ? fromParts(0, 0, 0, false, 0) : ((divisor = sumBy((tupledArg_1) => (new Decimal(tupledArg_1[1])), ratingValues, {
            GetZero: () => (new Decimal(0)),
            Add: op_Addition,
        }), equals_1(divisor, fromParts(0, 0, 0, false, 0)) ? fromParts(0, 0, 0, false, 0) : op_Division(sumBy((tupledArg_2) => op_Multiply(tupledArg_2[0], new Decimal(tupledArg_2[1])), ratingValues, {
            GetZero: () => (new Decimal(0)),
            Add: op_Addition,
        }), divisor))));
        ratingText = ((children_9 = [toText(printf("%.1f"))(fullRating)], react.createElement("span", {
            className: "rating",
        }, ...children_9)));
    }
    else {
        ratingText = (void 0);
    }
    return section_2(singleton(new Option_7(1, "survey-actions")), singleton(container(empty_1(), ofArray([react.createElement("div", {
        className: "is-pulled-right",
    }, saveButton, " ", finalizeButton), (children_13 = toList(delay(() => append(singleton_1(patternInput_1[0]), delay(() => append(singleton_1(" "), delay(() => append(singleton_1(patternInput_1[1]), delay(() => append(singleton_1(" "), delay(() => ((ratingText != null) ? singleton_1(value_5(ratingText)) : empty()))))))))))), react.createElement("div", {}, ...children_13))]))));
}

export function header(survey) {
    return hero(ofArray([new Option_8(6, new Color_IColor(4)), new Option_8(7, "header")]), singleton(body_1(empty_1(), singleton(container(empty_1(), toList(delay(() => append(singleton_1(h1(empty_1())(singleton(survey.Title))), delay(() => map_2((subtitle) => p(singleton(new Option_6(6)), singleton(subtitle)), survey.Subtitles))))))))));
}

export function primaryStyle(color) {
    let color_2;
    if (color != null) {
        const color_1 = color;
        const css = replace("{{text-color}}", (((((color_1.R * 0.299) + (color_1.G * 0.587)) + (color_1.B * 0.114)) / 255) > 0.5) ? "#000" : "#FFF", replace("{{primary-color}}", (color_2 = color_1, toText(printf("#%02x%02x%02x"))(color_2.R)(color_2.G)(color_2.B)), "\n            .button.is-primary,\n            .hero.is-primary {\n                background-color: {{primary-color}} !important;\n                color: {{text-color}} !important;\n            }\n\n            .hero.is-primary .title,\n            .hero.is-primary .subtitle,\n            .hero.is-primary .subtitle a:not(.button),\n            .hero.is-primary .subtitle strong {\n                color: {{text-color}} !important;\n            }\n\n            .is-checkradio[type=radio] + label::after,\n            .is-checkradio[type=radio] + label:after {\n                background-color: {{primary-color}} !important;\n            }\n\n            .calendar .calendar-date .date-item.is-active {\n                background-color: {{primary-color}} !important;\n                border-color: {{primary-color}} !important;\n                color: {{text-color}} !important;\n            }\n\n            .calendar .calendar-date .date-item.is-today,\n            .is-checkradio[type=checkbox] + label::after,\n            .is-checkradio[type=checkbox] + label:after,\n            .is-checkradio[type=radio]:hover:not([disabled]) + label::before,\n            .is-checkradio[type=radio]:hover:not([disabled]) + label:before,\n            .is-checkradio[type=checkbox]:hover:not([disabled]) + label::before,\n            .is-checkradio[type=checkbox]:hover:not([disabled]) + label:before {\n                border-color: {{primary-color}} !important;\n            }\n            "));
        return react.createElement("style", {}, css);
    }
    else {
        return "";
    }
}

export function view(res, localization, timeZoneOffset, model, dispatch) {
    let children_1;
    const questionViewServices = new QuestionViewServices(localization, timeZoneOffset, (questionId_1) => defaultArg(tryFind(questionId_1, model.UploadStates), new UploadState(0)));
    const sections = section_2(empty_1(), singleton(container(empty_1(), singleton((children_1 = map((section) => sectionView(res, equals(section.Id, model.SelectedSectionId), model.ShouldDisplayRequiredWarnings, model.Survey.RatingRange, questionViewServices, model.AnswerSuggestions, section, (questionId, answerValue) => {
        let tupledArg;
        dispatch(new SurveyMsg(3, new SurveyOperation(1, (tupledArg = [questionId, answerValue], new SurveyCommand(0, tupledArg[0], tupledArg[1])))));
    }, dispatch), model.Survey.Sections), react.createElement("form", {
        onSubmit: (e) => {
            e.preventDefault();
        },
        noValidate: true,
    }, ...children_1))))));
    const children_4 = [primaryStyle(model.Survey.Color), header(model.Survey), sections, actions(res, model, dispatch)];
    return react.createElement("div", {}, ...children_4);
}

