import { Option, p, h1 } from "./fable_modules/Fulma.2.16.0/Elements/Heading.fs.js";
import { ofArray, singleton, empty } from "./fable_modules/fable-library.3.7.12/List.js";
import { body, Option as Option_1, hero } from "./fable_modules/Fulma.2.16.0/Layouts/Hero.fs.js";
import { Option as Option_2, container } from "./fable_modules/Fulma.2.16.0/Layouts/Container.fs.js";
import { Color_IColor, Modifier_IModifier, TextAlignment_Option, Screen } from "./fable_modules/Fulma.2.16.0/Common.fs.js";
import { defaultArg } from "./fable_modules/fable-library.3.7.12/Option.js";
import { Option as Option_3, a } from "./fable_modules/Fulma.2.16.0/Elements/Button.fs.js";
import { toString } from "./fable_modules/fable-library.3.7.12/Types.js";
import { HTMLAttr } from "./fable_modules/Fable.React.7.4.3/Fable.React.Props.fs.js";
import { view as view_1 } from "./SurveyPage/View.fs.js";
import { Msg } from "./State.fs.js";

function title(text) {
    return h1(empty())(singleton(text));
}

function subtitle(text) {
    return p(singleton(new Option(6)), singleton(text));
}

export function fullScreenHero(color, children) {
    return hero(ofArray([new Option_1(5), new Option_1(6, color)]), singleton(body(empty(), singleton(container(ofArray([new Option_2(0), new Option_2(5, singleton(new Modifier_IModifier(5, new Screen(0), new TextAlignment_Option(0))))]), children)))));
}

export function finishedView(res, finishedSurvey) {
    const titleText = defaultArg(finishedSurvey.Title, res.ThankYou);
    const subtitleText = defaultArg(finishedSurvey.Description, res.YouFinished);
    let linkButton;
    const matchValue = finishedSurvey.Link;
    if (matchValue != null) {
        const text = matchValue[0];
        linkButton = a(ofArray([new Option_3(0, new Color_IColor(2)), new Option_3(17, singleton(new HTMLAttr(94, toString(matchValue[1]))))]), singleton(text));
    }
    else {
        linkButton = "";
    }
    return fullScreenHero(new Color_IColor(6), ofArray([title(titleText), subtitle(subtitleText), linkButton]));
}

export function view(model, dispatch) {
    const res = model.Resources;
    const matchValue = model.Page;
    switch (matchValue.tag) {
        case 0: {
            return fullScreenHero(new Color_IColor(7), ofArray([title(res.SomethingWentWrong), subtitle(matchValue.fields[0])]));
        }
        case 2: {
            return view_1(res, model.Localization, model.TimeZoneOffset, matchValue.fields[0], (arg) => {
                dispatch(new Msg(2, arg));
            });
        }
        case 3: {
            return finishedView(res, matchValue.fields[0]);
        }
        default: {
            return fullScreenHero(new Color_IColor(2), singleton(title(res.Loading)));
        }
    }
}

