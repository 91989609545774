import { newGuid } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Guid.js";
import { add } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Map.js";
import { uri, uint8 } from "./Encode.fs.js";
import { uri as uri_1, uint8 as uint8_1 } from "./Decode.fs.js";
import { empty } from "../../Fame.Survey/Survey.UI/fable_modules/Thoth.Json.7.0.0/Extra.fs.js";
import { ExtraCoders } from "../../Fame.Survey/Survey.UI/fable_modules/Thoth.Json.7.0.0/Types.fs.js";
import { decimal } from "../../Fame.Survey/Survey.UI/fable_modules/Thoth.Json.7.0.0/Encode.fs.js";
import { decimal as decimal_1 } from "../../Fame.Survey/Survey.UI/fable_modules/Thoth.Json.7.0.0/Decode.fs.js";

export const extra = (() => {
    let copyOfStruct, copyOfStruct_1, copyOfStruct_2;
    let extra_7;
    const extra_4 = new ExtraCoders((copyOfStruct = newGuid(), copyOfStruct), add("System.Byte", [uint8, (path) => ((token) => uint8_1(path, token))], empty.Coders));
    extra_7 = (new ExtraCoders((copyOfStruct_1 = newGuid(), copyOfStruct_1), add("System.Uri", [uri, (path_1) => ((token_1) => uri_1(path_1, token_1))], extra_4.Coders)));
    return new ExtraCoders((copyOfStruct_2 = newGuid(), copyOfStruct_2), add("System.Decimal", [decimal, (path_2) => ((value_4) => decimal_1(path_2, value_4))], extra_7.Coders));
})();

