import { Record } from "../Survey.UI/fable_modules/fable-library.3.7.12/Types.js";
import { record_type, lambda_type, class_type, union_type, string_type, unit_type } from "../Survey.UI/fable_modules/fable-library.3.7.12/Reflection.js";
import { FinishedSurvey$reflection, OpenSurvey$reflection, Survey$reflection } from "./AnswerQuestion.fs.js";
import { FSharpResult$2 } from "../Survey.UI/fable_modules/fable-library.3.7.12/Choice.js";

export class Services extends Record {
    constructor(LoadSurvey, SaveSurvey, FinishSurvey) {
        super();
        this.LoadSurvey = LoadSurvey;
        this.SaveSurvey = SaveSurvey;
        this.FinishSurvey = FinishSurvey;
    }
}

export function Services$reflection() {
    return record_type("Fame.Survey.Service.Services", [], Services, () => [["LoadSurvey", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [Survey$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", Survey$reflection()]], [["ErrorValue", string_type]]])]))], ["SaveSurvey", lambda_type(OpenSurvey$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])]))], ["FinishSurvey", lambda_type(OpenSurvey$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [FinishedSurvey$reflection(), string_type], FSharpResult$2, () => [[["ResultValue", FinishedSurvey$reflection()]], [["ErrorValue", string_type]]])]))]]);
}

