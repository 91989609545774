import { FSharpResult$2, Result_MapError } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Choice.js";
import { Validation_Matches_ZAEB317B, Validation_Matches_Z721C83C5, Validation_CheckMax_Z63A0D504, Validation_CheckMin_Z63A0D504, Validation_CheckMaxLength_Z524259A4, Validation_get_CheckNullOrEmpty, Validation_check, ValidationErrorModule_toEnglish } from "./Validation.fs.js";
import { singleton, append, delay, toList } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Seq.js";
import { singleton as singleton_1, ofArray } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/List.js";
import { isNullOrEmpty } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/String.js";
import { some } from "../../Fame.Survey/Survey.UI/fable_modules/fable-library.3.7.12/Option.js";

function toEnglish(fieldName, res) {
    return Result_MapError((err) => ValidationErrorModule_toEnglish(fieldName, err), res);
}

export function create(fieldName, ctor, validators, input) {
    return toEnglish(fieldName, Validation_check(ctor, toList(delay(() => append(singleton(Validation_get_CheckNullOrEmpty()), delay(() => validators)))))(input));
}

export function createString(fieldName, ctor, maxLen, input) {
    return toEnglish(fieldName, Validation_check(ctor, ofArray([Validation_get_CheckNullOrEmpty(), Validation_CheckMaxLength_Z524259A4(maxLen)]))(input));
}

export function createStringOption(fieldName, ctor, maxLen, input) {
    if (isNullOrEmpty(input)) {
        return new FSharpResult$2(0, void 0);
    }
    else {
        return toEnglish(fieldName, Validation_check((arg) => some(ctor(arg)), singleton_1(Validation_CheckMaxLength_Z524259A4(maxLen)))(input));
    }
}

export function createInt(fieldName, ctor, minVal, maxVal, input) {
    return toEnglish(fieldName, Validation_check(ctor, ofArray([Validation_CheckMin_Z63A0D504(minVal), Validation_CheckMax_Z63A0D504(maxVal)]))(input));
}

export function createDecimal(fieldName, ctor, minVal, maxVal, input) {
    return toEnglish(fieldName, Validation_check(ctor, ofArray([Validation_CheckMin_Z63A0D504(minVal), Validation_CheckMax_Z63A0D504(maxVal)]))(input));
}

export function createFloat(fieldName, ctor, minVal, maxVal, input) {
    return toEnglish(fieldName, Validation_check(ctor, ofArray([Validation_CheckMin_Z63A0D504(minVal), Validation_CheckMax_Z63A0D504(maxVal)]))(input));
}

export function createLike(fieldName, ctor, pattern, input) {
    return toEnglish(fieldName, Validation_check(ctor, ofArray([Validation_get_CheckNullOrEmpty(), Validation_Matches_Z721C83C5(pattern)]))(input));
}

export function createMatch(fieldName, ctor, re, input) {
    return toEnglish(fieldName, Validation_check(ctor, ofArray([Validation_get_CheckNullOrEmpty(), Validation_Matches_ZAEB317B(re)]))(input));
}

