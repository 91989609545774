import { Record, Union } from "./fable_modules/fable-library.3.7.12/Types.js";
import { record_type, class_type, lambda_type, option_type, union_type, string_type } from "./fable_modules/fable-library.3.7.12/Reflection.js";
import { update as update_1, init as init_1, SurveyMsg$reflection, SurveyPage$reflection } from "./SurveyPage/State.fs.js";
import { Survey, Survey$reflection, FinishedSurvey$reflection } from "../Survey/AnswerQuestion.fs.js";
import { Services, getServices as getServices_1, Services$reflection } from "./Service.fs.js";
import { Resources$reflection } from "../Survey/Resources.fs.js";
import { Localization$reflection } from "../../Fame/Localization/Localization.fs.js";
import { retn } from "./fable_modules/FsToolkit.ErrorHandling.2.13.0/AsyncResult.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "./fable_modules/Fable.Elmish.3.1.0/./cmd.fs.js";
import { concatNewLine } from "../../Fame/Core/String.fs.js";
import { getAllMessages } from "../../Fame/Core/Exception.fs.js";
import { Cmd_map, Cmd_none } from "./fable_modules/Fable.Elmish.3.1.0/cmd.fs.js";
import { some } from "./fable_modules/fable-library.3.7.12/Option.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Invalid", "Loading", "Survey", "Finished"];
    }
}

export function Page$reflection() {
    return union_type("Fame.Survey.UI.State.Page", [], Page, () => [[["Item", string_type]], [], [["Item", SurveyPage$reflection()]], [["Item", FinishedSurvey$reflection()]]]);
}

export class Model extends Record {
    constructor(Page, GetServices, Resources, Localization, TimeZoneOffset) {
        super();
        this.Page = Page;
        this.GetServices = GetServices;
        this.Resources = Resources;
        this.Localization = Localization;
        this.TimeZoneOffset = TimeZoneOffset;
    }
}

export function Model$reflection() {
    return record_type("Fame.Survey.UI.State.Model", [], Model, () => [["Page", Page$reflection()], ["GetServices", lambda_type(option_type(Survey$reflection()), Services$reflection())], ["Resources", Resources$reflection()], ["Localization", Localization$reflection()], ["TimeZoneOffset", class_type("System.TimeSpan")]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["SurveyLoadFailed", "SurveyLoadSuccess", "SurveyMsg"];
    }
}

export function Msg$reflection() {
    return union_type("Fame.Survey.UI.State.Msg", [], Msg, () => [[["Item", string_type]], [["Item", Survey$reflection()]], [["Item", SurveyMsg$reflection()]]]);
}

export function init(resources, localization, timeZoneOffset, loadOptionsResult) {
    if (loadOptionsResult.tag === 0) {
        const defaultServices = getServices_1(loadOptionsResult.fields[0]);
        return [new Model(new Page(1), (surveyOption_1) => {
            let surveyOption;
            return new Services((surveyOption = surveyOption_1, () => ((surveyOption == null) ? defaultServices.LoadSurvey() : retn(surveyOption))), defaultServices.AutoSaveSurvey, defaultServices.SaveSurvey, defaultServices.FinishSurvey, defaultServices.UploadFile);
        }, resources, localization, timeZoneOffset), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, defaultServices.LoadSurvey, void 0, (_arg1_1) => {
            if (_arg1_1.tag === 1) {
                return new Msg(0, _arg1_1.fields[0]);
            }
            else {
                return new Msg(1, _arg1_1.fields[0]);
            }
        }, (arg_1) => (new Msg(0, concatNewLine(getAllMessages(arg_1)))))];
    }
    else {
        return [new Model(new Page(0, loadOptionsResult.fields[0]), (_arg1) => {
            throw (new Error("loadoptions invalid"));
        }, resources, localization, timeZoneOffset), Cmd_none()];
    }
}

export function update(msg, model) {
    const matchValue = [msg, model.Page];
    let pattern_matching_result, error, survey, surveyModel, surveyMsg;
    if (matchValue[0].tag === 1) {
        if (matchValue[1].tag === 1) {
            pattern_matching_result = 1;
            survey = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[0].tag === 2) {
        if (matchValue[1].tag === 2) {
            pattern_matching_result = 2;
            surveyModel = matchValue[1].fields[0];
            surveyMsg = matchValue[0].fields[0];
        }
        else {
            pattern_matching_result = 3;
        }
    }
    else if (matchValue[1].tag === 1) {
        pattern_matching_result = 0;
        error = matchValue[0].fields[0];
    }
    else {
        pattern_matching_result = 3;
    }
    switch (pattern_matching_result) {
        case 0: {
            console.error(some(error));
            return [new Model(new Page(0, "Survey could not be loaded."), model.GetServices, model.Resources, model.Localization, model.TimeZoneOffset), Cmd_none()];
        }
        case 1: {
            return [new Model((survey.tag === 0) ? (new Page(2, init_1(survey.fields[0]))) : (new Page(3, survey.fields[0])), model.GetServices, model.Resources, model.Localization, model.TimeZoneOffset), Cmd_none()];
        }
        case 2: {
            let pattern_matching_result_1, finishedSurvey_1;
            if (surveyMsg.tag === 13) {
                if (surveyMsg.fields[0].tag === 2) {
                    if (surveyMsg.fields[0].fields[0].tag === 0) {
                        pattern_matching_result_1 = 0;
                        finishedSurvey_1 = surveyMsg.fields[0].fields[0].fields[0];
                    }
                    else {
                        pattern_matching_result_1 = 1;
                    }
                }
                else {
                    pattern_matching_result_1 = 1;
                }
            }
            else {
                pattern_matching_result_1 = 1;
            }
            switch (pattern_matching_result_1) {
                case 0: {
                    return [new Model(new Page(3, finishedSurvey_1), model.GetServices, model.Resources, model.Localization, model.TimeZoneOffset), Cmd_none()];
                }
                case 1: {
                    const patternInput = update_1(model.GetServices(new Survey(0, surveyModel.Survey)), surveyMsg, surveyModel);
                    return [new Model(new Page(2, patternInput[0]), model.GetServices, model.Resources, model.Localization, model.TimeZoneOffset), Cmd_map((arg0_3) => (new Msg(2, arg0_3)), patternInput[1])];
                }
            }
        }
        case 3: {
            return [model, Cmd_none()];
        }
    }
}

